import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table"; // https://tanstack.com/table/v8  https://tanstack.com/table/v8/docs/examples/react/row-selection

import Button from "react-bootstrap/Button";
import FormTextInput from "./common/formTextInput";
import { saveContact, getContacts } from "../services/contactService";

import { MdSearch, MdDone, MdMode } from "react-icons/md";
import "./sideBar.css";

// let op sideBarContacts levert objectArray terug in setValue met name en id

const SideBarContacts = ({ setValue, setValueId, setShowSideBar }) => {
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState({});
  const [contactSearch, setContactSearch] = useState("");
  const [search, setSearch] = useState(false);
  const formikRef = useRef();
  //  const [data, setData] = React.useState(() => [...defaultData]);
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = React.useState({});

  const [columnResizeMode, setColumnResizeMode] = useState("onChange"); // onEnd is alternatief

  const editRow = (id) => {
    const oldcontact = contact;
    const newcontact = data.filter((c) => c._id === id)[0];
    setContact(newcontact);
    formikRef.current?.resetForm();
  };

  const selectRow = (id) => {
    const newcontact = data.filter((c) => c._id === id)[0];
    const retObj = { name: newcontact.name, id: newcontact._id };
    const ret = [];
    ret.push(retObj);

    setValue(ret);
    setShowSideBar(false);
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => (
        <div
          onClick={() => {
            onSelectRow(info.row.index);
          }}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <span>Naam</span>,
      footer: (info) => info.column.id,
      minSize: 200,
      size: 200,
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.getValue(),
      header: () => <span>Email</span>,
      footer: (info) => info.column.id,
      minSize: 300,
      size: 300,
    }),
    columnHelper.accessor("phonenr", {
      header: "Telefoonnr",
      footer: (info) => info.column.id,
      minSize: 120,
      size: 120,
    }),
    columnHelper.accessor("_id", {
      header: "",
      cell: (info) => (
        <MdMode
          onClick={() => {
            editRow(info.getValue());
          }}
        />
      ),
      minSize: 20,
      size: 20,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    const fetchData = async (contactSearch) => {
      const { data: contactsDb } = await getContacts(
        null,
        contactSearch && contactSearch != "" ? "search=" + contactSearch : "",
        "find"
      );
      setData(contactsDb);
      setSearch(false);
    };

    if (contactSearch || search) fetchData(contactSearch);
  }, [contactSearch, search]);

  const doSubmit = async (values) => {
    const contact = { ...values };
    const { data: contactDb } = await saveContact(contact);

    const retObj = { name: contactDb.name, id: contactDb._id };
    const ret = [];
    ret.push(retObj);

    setValue(ret);
    setShowSideBar(false);
  };

  const onSelectRow = (index) => {
    const retObj = { name: data[index].name, id: data[index]._id };
    const ret = [];
    ret.push(retObj);

    setValue(ret);
    setShowSideBar(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={contact}
        innerRef={formikRef}
        validationSchema={Yup.object({
          name: Yup.string().required("Vereist"),
          //value: Yup.string().required("Vereist"),
          //            typename: Yup.number().min(1).required("Kies"),
        })}
        onSubmit={async (values) => {
          doSubmit(values);
        }}
      >
        {({ handleChange, handleBlur, setFieldValue, values }) => (
          <div className="container">
            <Form>
              <FormTextInput
                className="formLabel"
                label="Zoek"
                name="search"
                type="text"
              />
              <MdSearch
                onClick={() => {
                  setContactSearch(values.search);
                  setSearch(true);
                }}
              />
              <div className="p-2 sidebarTable">
                <table className="sidebarTable">
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            className="sidebarTable"
                            key={header.id}
                            border="1"
                            style={{
                              width: header.column.columnDef.size,
                              kborder: 1,
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        //                        onClick={() => {
                        //                          onSelectRow(row.index);
                        //                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            className="sidebarTable"
                            key={cell.id}
                            style={{
                              border: 1,
                            }}
                          >
                            {cell.getValue() && // RBo  wanneer value is null of key ontbreekt in object geeft crash
                              flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="h-2" />
                <div className="flex items-center gap-2">
                  <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<<"}
                  </button>
                  <button
                    className="border rounded p-1"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {"<"}
                  </button>
                  <button
                    className="border rounded p-1"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {">"}
                  </button>
                  <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    {">>"}
                  </button>
                  <span className="flex items-center gap-1">
                    Page
                    <strong>
                      {table.getState().pagination.pageIndex + 1} of{" "}
                      {table.getPageCount()}
                    </strong>
                    | Go to page:
                    <input
                      type="number"
                      defaultValue={table.getState().pagination.pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        table.setPageIndex(page);
                      }}
                      className="border p-1 rounded" // w-16"
                      style={{ width: 60 }}
                      min="1"
                    />
                  </span>
                  <select
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <FormTextInput
                className="formLabel"
                label="Naam"
                name="name"
                type="text"
              />
              <FormTextInput
                className="formLabel"
                label="Email"
                name="email"
                type="email"
              />
              <FormTextInput
                className="formLabel"
                label="Telefoonnr"
                name="phonenr"
                type="text"
              />
              <br />
              <Button type="submit">Opslaan en selecteren</Button>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default SideBarContacts;
