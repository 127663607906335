import React from "react";
import { useField } from "formik";

const FormSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  if (!label || label === "") {
    return (
      <>
        <select {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  }
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <br />
      <select {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default FormSelect;

/*
bijvoorbeeld:

            <FormSelect label="Relatie" name="relation.name">
              <option value="">Select a job type</option>
              <option value="designer">Designer</option>
              <option value="development">Developer</option>
              <option value="product">Product Manager</option>
              <option value="other">Other</option>
            </FormSelect>

*/
