import React, { useState, useEffect, useCallback } from "react";
import { Image } from "react-bootstrap";
import useResizeObserver from "./useResizeObserver";

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

//TODO: er wordt nu uitgegeaan van een aspect ratio 16/9 = 56.15% zie app.cs className="video-responsive" deze moet nog gecontroleerd worden met  width/height ratio in link
export default function Media({
  media,
  roundImage,
  mediaText,
  setCellHeight,
  index,
  heightCarousel,
}) {
  const m = media;
  const r = roundImage && roundImage === true ? true : false;
  const text = mediaText;
  const [indexC, setIndexC] = useState(index);

  const [height, setHeight] = useState(0);
  const [heightC, setHeightC] = useState(heightCarousel);
  const [eventlistenerInstalled, setEventlistenerInstalled] = useState(false);

  //  const ref = useRef(null);
  const onResize = useCallback((target) => {
    //    console.log("useResizeObserver", target.clientHeight);
    if (setCellHeight) setCellHeight(target.clientHeight, indexC);
  }, []);

  // https://codefrontend.com/resize-observer-react/
  const ref = useResizeObserver(onResize);

  /*
  const setTheHeight = (h) => {
    setHeight(h);
    setCellHeight(h);
  };
  */

  const resizeWindow = () => {
    if (
      ref &&
      ref.current &&
      height !== ref.current.clientHeight &&
      ref.current.clientHeight > 35 // veilige marge met eerste (24)
    ) {
      setHeight(ref.current.clientHeight);
      if (setCellHeight) setCellHeight(ref.current.clientHeight, indexC);
      //      console.log("height ... ", ref.current.clientHeight);
    }
  };

  useEffect(() => {
    if (eventlistenerInstalled === false) {
      window.addEventListener("resize", resizeWindow);
      setEventlistenerInstalled(true);
    }
    resizeWindow();
  }, [ref, heightC, eventlistenerInstalled, resizeWindow]);

  const imageLoaded = () => {
    resizeWindow();
  };

  if (!media) return <div />;

  const divStyle = {};
  if (heightC) {
    divStyle.height = `${heightC}px`;
  }
  if (r === true) {
    divStyle.borderRadius = "50%";
    divStyle.overflow = "hidden";
  }
  /*
    backgroundColor: "blue",
    borderRadius: "50%",
    overflow: "hidden",
    height: "372px",
  };
*/

  if (!m || !m.type) return <div />;
  if (m.type === "image" && r === true && (!text || text.length < 5)) {
    return (
      <div className="App" ref={ref}>
        <Image
          onLoad={imageLoaded()}
          src={`${process.env.REACT_APP_SRV_URL}/${m.path}`}
          className="pageTopImage"
          style={{
            backgroundColor: "blue",
            borderRadius: "50%",
            overflow: "hidden",
            height: `${heightC ? `${heightC.toString()}px` : "100%"}`,
          }}
        />
      </div>
    );
  } else if (m.type === "image" && r === false && (!text || text === "")) {
    return (
      <div className="App" ref={ref}>
        <Image
          onLoad={imageLoaded()}
          src={`${process.env.REACT_APP_SRV_URL}/${m.path}`}
          className="pageTopImage"
        />
      </div>
    );
  } else if (m.type === "image" && r === true && text && text !== "") {
    return (
      <div className="App" ref={ref}>
        <Image
          onLoad={imageLoaded()}
          src={`${process.env.REACT_APP_SRV_URL}/${m.path}`}
          className="pageTopImage"
          style={{ borderRadius: "50%", overflow: "hidden" }}
        />
        <>
          {/* text */}
          <br />
        </>
        <h6>{m.name}</h6>
      </div>
    );
  } else if (m.type === "image" && r === false && text && text !== "") {
    return (
      <div className="App" ref={ref}>
        <Image
          onLoad={imageLoaded()}
          src={`${process.env.REACT_APP_SRV_URL}/${m.path}`}
          className="pageTopImage"
        />
        <>
          {/* text */}
          <br />
        </>
        <h6>{m.name}</h6>
      </div>
    );
  } else if (m.type === "youtube") {
    const embedcode = getYahooId(m.movieImageUrl);

    return (
      <div className="App" ref={ref}>
        <YoutubeEmbed embedId={embedcode} />
        <h6>{m.name}</h6>
      </div>
    );
  } else {
    return <div />;
  }
}

const getYahooId = (youtubeUrl) => {
  // '<iframe width="1053" height="592" src="https://www.youtube.com/embed/t808BgOjZPo" title="Romeo Santos, SUS HUELLAS   / Marco y Sara bachata style , workshop en  ISTAMBUL  salsa congress" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
  const lefts = "https://www.youtube.com/embed/";
  let url = youtubeUrl.substring(youtubeUrl.indexOf(lefts) + lefts.length);
  let embedcode = url.substring(0, url.indexOf('"'));

  return embedcode;
};
