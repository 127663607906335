import React from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      onChange: this.props.onChange,
      id: this.props.id,
      identifier: this.props.identifier,
      color: {
        hex: this.props.colorHex,
        //        r: "19",
        //        g: "47",
        //        b: "241",
        //        a: "1",
      },
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    //    console.log("colorpicker ", color);
    if (color.hex !== this.state.color.hex) {
      this.setState({ color: color.hex });
      this.state.onChange(this.props.identifier, this.props.id, color.hex);
    }
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "16px",
          height: "20px",
          borderRadius: "2px",
          //          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
          background: `${this.state.colorHex}`,
        },
        swatch: {
          padding: "2px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.state.color}
              onChangeComplete={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
