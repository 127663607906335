import React from "react";
/*
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
*/

const SurveyTable = ({ surveys, setSurveyId }) => {
  /*  const surveyColumns = [
    {
      dataField: "nr",
      text: "Nr",
    },
    {
      dataField: "name",
      text: "Naam",
      filter: textFilter(),
    },
  ];

  const surveyRowEvents = {
    onClick: (e, row, rowIndex) => {
      setSurveyId(row._id);
    },
  };

  const surveyPagination = paginationFactory({
    sizePerPageList: [
      {
        text: "1",
        value: 1,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "Alle",
        value: surveys.length,
      },
    ],
  });
*/
  return <div />;
  /*
  return (
    <BootstrapTable
      bootstrap4
      keyField="_id"
      data={surveys}
      columns={surveyColumns}
      striped
      hover
      condensed
      pagination={surveyPagination}
      filter={filterFactory()}
      rowEvents={surveyRowEvents}
    ></BootstrapTable>
  ); */
};

export default SurveyTable;
