import React, { useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

import FormTextInput from "../../common/formTextInput";
import FormTextInputSearch from "../../common/formTextInputSearch";
// import FormSelectList from "../../common/formSelectList";
import TextEditor from "../../common/textEditor2";

import { saveProject } from "../../../services/projectService";

//  const linkTo = "/project?id=" + projectId; parameter optioneel

const ProjectForm = ({ project, setProject, customerId, customers }) => {
  const [localProject, setLocalProject] = useState({ description: "" });

  const doSubmit = async (values) => {
    const project = { ...values, customerName: "" };
    const { data: projectDb } = await saveProject(project);
    setProject(projectDb);
  };

  const findCustomerName = (cId) => {
    if (!cId) return "";
    if (!customers) return "";
    const c = customers.find((c) => c._id === cId);
    if (c && c.name) return c.name;
  };

  useEffect(() => {
    if (project && project !== localProject) {
      const customerName = findCustomerName(project.customerId);
      setLocalProject({
        ...project,
        customerName: customerName,
      });
    } else if (!project && localProject._id) {
      const p = { ...localProject };
      for (let k in p) {
        p[k] = "";
      }
      p.customerId = customerId;
      p.customerName = findCustomerName(customerId);
      setLocalProject(p);
    } else if (!project && customerId) {
      const p = {
        customerId: customerId,
        customerName: findCustomerName(customerId),
      };
      setLocalProject(p);
    }
  }, [customerId, project]);

  useFormikContext();

  if (!localProject) {
    return <div />;
  } else {
    return (
      <>
        <h2>Projecten</h2>
        <Formik
          enableReinitialize
          initialValues={localProject}
          validationSchema={Yup.object({
            name: Yup.string().required("Vereist"),
            //value: Yup.string().required("Vereist"),
            //            typename: Yup.number().min(1).required("Kies"),
          })}
          onSubmit={async (values) => {
            doSubmit(values);
          }}
        >
          {({ handleChange, handleBlur, setFieldValue, values }) => (
            <div className="container">
              <Form>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Nummer"
                      name="nr"
                      type="text"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInputSearch
                      label="Klant"
                      name="customerName"
                      disabled={true}
                      onClick={() => alert("nog niet geimplementeerd")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Naam"
                      name="name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Contact persoon"
                      name="contact"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Email"
                      name="email"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Telefoonnummer"
                      name="phone"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="form-group row">
                    <h6>Omschrijving</h6>
                    <TextEditor
                      setFieldValue={(val) => setFieldValue("description", val)}
                      value={values.description}
                      name="text"
                    />
                  </div>
                </div>
                <br />
                <br />
                <Button type="submit">Opslaan</Button>
                <br />
              </Form>
            </div>
          )}
        </Formik>
      </>
    );
  }
};

export default ProjectForm;
