import React, { useState, useEffect } from "react";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";

import Button from "react-bootstrap/Button";

import SideBar from "./sideBar";

import FormTextInput from "./common/formTextInput";
import FormTextArea from "./common/formTextArea";
import FormSelectList from "./common/formSelectList";
import FormDateTimeInput from "./common/formDateTimeInput";
import FormCheckbox from "./common/formCheckbox";

import { MdCompareArrows } from "react-icons/md";
import "survey-core/modern.min.css";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";

import { getSurveys } from "../services/surveyService";
import { saveSurveyResult } from "../services/surveyResultService";

const InterviewInput = ({ user }) => {
  const [survey, setSurvey] = useState(null);
  const [surveys, setSurveys] = useState(null);
  const [surveyList, setSurveyList] = useState(null);
  const [loadingSurveys, setLoadingSurveys] = useState(true);
  const [interview, setInterview] = useState({
    interviewerName: "", //user.name
    interviewerContactId: "",
    intervieweeName: "",
    intervieweeContactId: "",
    interviewDatetime: { date: "", time: "" },
    description: "",
    surveyId: "",
    test: false,
  });

  const [sideBarActivator, setSideBarActivator] = useState(null);

  const [showSideBar, setShowSideBar] = useState(false);

  const handleCloseSideBar = () => setShowSideBar(false);
  const handleShowSideBar = () => setShowSideBar(true);

  const [sideBarValue, setSideBarValue] = useState(null);

  const OpenSideBar = ({ handleShow, activator }) => {
    return (
      <>
        <MdCompareArrows
          onClick={() => {
            handleShow();
            setSideBarActivator(activator);
          }}
        />
      </>
    );
  };
  /*
  setInterview({
    interviewerName: user.name,
    interviewerContactId: "",
    intervieweeName: "Onbekend",
    intervieweeContactId: "",
    interviewdatetime: { date: "", time: "" },
    description: "",
    surveyId: "",
    test: false,
  });
  
  useEffect(() => {
    setFieldValue("interviewerName", sideBarValue);
    setFieldValue("description", "done");
  }, [sideBarValue, sideBarValueId]);
*/
  useEffect(() => {
    const fetchData = async () => {
      setLoadingSurveys(true);
      const { data: surveysDb } = await getSurveys();
      setSurveys(surveysDb);
      const surveyList = { options: [] };
      surveyList.options.push({ value: null, name: "kies..." });
      surveysDb.map((s) => {
        surveyList.options.push({ value: s._id, name: s.name });
      });
      setSurveyList(surveyList);

      setLoadingSurveys(false);
    };
    fetchData();
  }, []);

  const doSubmit = async (values) => {
    // alert(JSON.stringify(values, null, 2));
    const iv = { ...values };
    setInterview(iv);
    const sv = surveys.find((s) => s._id === values.surveyId);
    setSurvey(sv);
  };

  const handlerDefinedOutside = (value, setFieldValue) => {
    // let op value is array met objects {name: "", id: ""}
    const val = value[0];
    setFieldValue(sideBarActivator.name, val.name);
    setFieldValue(sideBarActivator.id, val.id);
  };

  if (loadingSurveys) {
    return <div />;
  } else if (!survey) {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={interview}
          validationSchema={Yup.object({
            interviewerName: Yup.string().required("Vereist"),
            intervieweeName: Yup.string().required("Vereist"),
            surveyId: Yup.string().required("Vereist"),
          })}
          onSubmit={async (values) => {
            doSubmit(values);
          }}
        >
          {({ handleChange, handleBlur, setFieldValue, values }) => (
            <div className="container">
              <SideBar
                showSideBar={showSideBar}
                setShowSideBar={setShowSideBar}
                handleCloseSideBar={handleCloseSideBar}
                placement={"end"}
                scroll={true}
                active={"contacts"}
                setValue={(value) => {
                  setSideBarValue(value);
                  handlerDefinedOutside(value, setFieldValue);
                }}
                //                setValueId={(valueId) =>
                //                  setFieldValue("interviewerContactId", valueId)
                //                }
              />

              <Form>
                {
                  <h6>
                    {values.interviewerName} {values.description}
                  </h6>
                }
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Interviewer"
                      name="interviewerName"
                      value={values.interviewerName}
                      type="text"
                      onChange={handleChange}
                      disabled={true}
                    />

                    <OpenSideBar
                      handleShow={handleShowSideBar}
                      activator={{
                        name: "interviewerName",
                        id: "interviewerContactId",
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Geinterviewde"
                      name="intervieweeName"
                      type="text"
                      onChange={handleChange}
                      disabled={true}
                    />
                    <OpenSideBar
                      handleShow={handleShowSideBar}
                      activator={{
                        name: "intervieweeName",
                        id: "intervieweeContactId",
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormDateTimeInput
                      className="formLabel"
                      label="Datum tijd (dd-mm-yyyy hh:mm )"
                      name="interviewDatetime"
                      format="dd-MM-yyyy HH:mm"
                      onChange={(e) => {
                        handleChange(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group row">
                    <FormTextArea
                      className="formLabel"
                      label="Opmerkingen"
                      name="description"
                      value={values.description}
                      type="text"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormSelectList
                      label="Enquete"
                      list={surveyList}
                      name="surveyId"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormCheckbox
                      label="Test: een test komt niet in de exports terecht"
                      name="test"
                    />
                  </div>
                </div>

                <br />
                <br />
                <Button type="submit">Doorgaan</Button>
              </Form>
            </div>
          )}
        </Formik>
      </>
    );
  }

  /*      <SurveyList
        user={user}
        title={"Invoeren afgenomen interviews"}
        surveys={surveys}
        setSurvey={setSurvey}
      />
*/

  if (survey) {
    const jsonstr = survey.surveyJson.replaceAll("\n", "");
    //    var fixedJSON = jsonstr.replace(
    //      /(['"])?([a-zA-Z0-9_]+)(['"])?:/g,
    //      '"$2": '
    //    );
    const json = JSON.parse(jsonstr);
    const localsurvey = new Model(json);

    localsurvey.onComplete.add((sender) => {
      const res = sender.data;
      const result = JSON.stringify(res, null, 2);
      const iv = { ...interview, surveyResults: result };
      saveSurveyResult(iv);
      setInterview({
        interviewerName: "", //user.name
        interviewerContactId: "",
        intervieweeName: "",
        intervieweeContactId: "",
        interviewDatetime: { date: "", time: "" },
        description: "",
        surveyId: "",
        test: false,
      });
      setSurvey(null);
    });
    return (
      <>
        <Survey model={localsurvey} />
      </>
    );
  }
  return <div />;
};

export default InterviewInput;

const jsno = {
  questionErrorLocation: "bottom",
  progressBarType: "pages",
  showProgressBar: "bottom",
  pages: [
    {
      page: 1,
      title: "Entree enquete",
      elements: [
        {
          type: "html",
          html: "<p>We zijn benieuwd naar jouw mening en ervaring over Ontmoetingen Online. Om van te leren en het nog beter te doen voor mensen zoals jijzelf. Mogen we je daarover een paar vragen stellen?<br/><br/>Het gesprek en/of het invullen van de vragen zal ongeveer 20 minuten duren. Deelname is vrijwillig, foute antwoorden bestaan niet en we verwerken je antwoorden anoniem. Dit betekent dat niemand (buiten de interviewer) weet wat jij geantwoord hebt.<br/><br/>Wanneer je een vraag niet begrijpt of een antwoord niet weet, geef dit dan alsjeblieft aan. Alvast veel dank voor je tijd.</p>",
        },
        {
          type: "boolean",
          name: "test",
          title:
            "Test komt niet voor in de productie output, dus ongestoord testen!",
        },
      ],
    },
    {
      page: 2,
      title:
        "Dit is alleen voor achteraf invoeren na een interview en in de volgende versie dus optioneel",
      elements: [
        {
          name: "participantName",
          title: "Naam deelnemer:",
          isRequired: true,
          type: "text",
        },
        {
          name: "interviewedByName",
          title: "Naam interviewer:",
          isRequired: true,
          type: "text",
        },
        {
          name: "interviewDate",
          title: "Datum interview:",
          type: "text",
          isRequired: true,
          inputType: "date",
        },
      ],
    },
    {
      page: 3,
      title: "Eenzaamheid",
      elements: [
        {
          type: "matrix",
          isRequired: true,
          name: "In hoeverre ben je het eens met de volgende stellingen:",
          columns: ["Zeker", "ja", "min of meer", "nee", "zeker niet"],
          rows: [
            {
              value: "s1",
              text: "Er is altijd wel iemand in mijn omgeving bij wie ik met mijn dagelijkse probleempjes terecht kan",
            },
            { value: "e1", text: "Ik mis een echt goede vriend of vriendin" },
            { value: "e2", text: "Ik ervaar een leegte om me heen" },
            {
              value: "s2",
              text: "Er zijn genoeg mensen op wie ik in geval van narigheid kan terugvallen",
            },
            { value: "e3", text: "Ik mis gezelligheid om me heen" },
            {
              value: "e4",
              text: "Ik vind mijn kring van kennissen te beperkt",
            },
            {
              value: "s3",
              text: "Ik heb veel mensen op wie ik volledig kan vertrouwen",
            },
            {
              value: "s4",
              text: "Er zijn voldoende mensen met wie ik me nauw verbonden voel",
            },
            { value: "e5", text: "Ik mis mensen om me heen" },
            { value: "e6", text: "Vaak voel ik me in de steek gelaten" },
            {
              value: "s5",
              text: "Wanneer ik daar behoefte aan heb kan ik altijd bij mijn vrienden terecht",
            },
          ],
        },
      ],
    },
  ],
};

const json2 = {
  questionErrorLocation: "bottom",
  progressBarType: "pages",
  showProgressBar: "bottom",
  pages: [
    {
      page: 1,
      title: "Entree enquete",
      elements: [
        {
          type: "html",
          html: "<p>We zijn benieuwd naar jouw mening en ervaring over Ontmoetingen Online. Om van te leren en het nog beter te doen voor mensen zoals jijzelf. Mogen we je daarover een paar vragen stellen?<br/><br/>Het gesprek en/of het invullen van de vragen zal ongeveer 20 minuten duren. Deelname is vrijwillig, foute antwoorden bestaan niet en we verwerken je antwoorden anoniem. Dit betekent dat niemand (buiten de interviewer) weet wat jij geantwoord hebt.<br/><br/>Wanneer je een vraag niet begrijpt of een antwoord niet weet, geef dit dan alsjeblieft aan. Alvast veel dank voor je tijd.</p>",
        },
        {
          type: "boolean",
          name: "test",
          title:
            "Test komt niet voor in de productie output, dus ongestoord testen!",
        },
      ],
    },
    {
      page: 2,
      title:
        "Dit is alleen voor achteraf invoeren na een interview en in de volgende versie dus optioneel",
      elements: [
        {
          name: "participantName",
          title: "Naam deelnemer:",
          isRequired: true,
          type: "text",
        },
        {
          name: "interviewedByName",
          title: "Naam interviewer:",
          isRequired: true,
          type: "text",
        },
        {
          name: "interviewDate",
          title: "Datum interview:",
          type: "text",
          isRequired: true,
          inputType: "date",
        },
      ],
    },
    {
      page: 3,
      title: "Eenzaamheid",
      elements: [
        {
          type: "matrix",
          isRequired: true,
          name: "In hoeverre ben je het eens met de volgende stellingen:",
          columns: ["Zeker", "ja", "min of meer", "nee", "zeker niet"],
          rows: [
            {
              value: "s1",
              text: "Er is altijd wel iemand in mijn omgeving bij wie ik met mijn dagelijkse probleempjes terecht kan",
            },
            { value: "e1", text: "Ik mis een echt goede vriend of vriendin" },
            { value: "e2", text: "Ik ervaar een leegte om me heen" },
            {
              value: "s2",
              text: "Er zijn genoeg mensen op wie ik in geval van narigheid kan terugvallen",
            },
            { value: "e3", text: "Ik mis gezelligheid om me heen" },
            {
              value: "e4",
              text: "Ik vind mijn kring van kennissen te beperkt",
            },
            {
              value: "s3",
              text: "Ik heb veel mensen op wie ik volledig kan vertrouwen",
            },
            {
              value: "s4",
              text: "Er zijn voldoende mensen met wie ik me nauw verbonden voel",
            },
            { value: "e5", text: "Ik mis mensen om me heen" },
            { value: "e6", text: "Vaak voel ik me in de steek gelaten" },
            {
              value: "s5",
              text: "Wanneer ik daar behoefte aan heb kan ik altijd bij mijn vrienden terecht",
            },
          ],
        },
      ],
    },
  ],
};
