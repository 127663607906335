import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table"; // https://tanstack.com/table/v8  https://tanstack.com/table/v8/docs/examples/react/row-selection

import Media from "../common/media";
import { MdMode, MdDeleteOutline } from "react-icons/md";

const SiteMediaTable = ({
  siteMedias,
  handleSMTableClick,
  handleSMTableDeleteClick,
}) => {
  const [data, setData] = useState([]);
  const [columnResizeMode, setColumnResizeMode] = useState("onChange"); // onEnd is alternatief

  useEffect(() => {
    setData(siteMedias);
  }, [siteMedias]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("_id", {
      cell: (info) => (
        <div>
          <Media
            media={info.row.original}
            src={`${process.env.REACT_APP_SRV_URL}/${info.row.original.path}`}
            roundImage={false}
          ></Media>
        </div>
      ),
      header: () => <span>Media</span>,
      footer: (info) => info.column.id,
      minSize: 50,
      size: "20%",
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Naam</span>,
      footer: (info) => info.column.id,
      minSize: 100,
      size: "60%",
    }),
    columnHelper.accessor("type", {
      cell: (info) => (
        <span>
          <h6>{info.getValue()}</h6>{" "}
          <MdMode
            size={30}
            onClick={() => {
              // editRow(info.getValue());
              handleSMTableClick(
                siteMedias.filter((s) => s._id === info.row.original._id)[0],
                0
              );
            }}
          />
          <MdDeleteOutline
            size={30}
            onClick={() => {
              //              const response = confirm(
              //                "Weet je zeker dat je deze wil verwijderen?"
              //              );

              //              if (response) {
              handleSMTableDeleteClick(
                siteMedias.filter((s) => s._id === info.row.original._id)[0],
                0
              );
              //              }
            }}
          />
        </span>
      ),

      header: () => <span>Type</span>,
      footer: (info) => info.column.id,
      minSize: 100,
      size: "15%",
    }),
    /*     columnHelper.display({
      id: "actionscolumn",
      header: () => <span>Acties</span>,
      cell: (props) => <h6>pipo</h6>,
            <span>
          <MdMode
            size={50}
            onClick={() => {
              // editRow(info.getValue());
              alert(props.row.original._id + " edit");
            }}
          />
          <h6>pipo</h6>
          <MdDeleteOutline
            onClick={() => {
              // editRow(info.getValue());
              alert(props.row.original._id + " delete");
            }}
          />
        </span>
      ),
      minSize: 50,
      size: "15%",
    }), */
  ];

  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="p-2 sidebarTable">
      <table className="sidebarTable">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className="sidebarTable"
                  key={header.id}
                  style={{
                    width: header.column.columnDef.size,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => {
                //                onSelectRow(row.original._id);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td className="sidebarTable" key={cell.id}>
                  {cell.getValue() && // RBo  wanneer value is null of key ontbreekt in object geeft crash
                    flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </button>
        <span className="flex items-center gap-1">
          Page
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded" // w-16"
            style={{ width: 60 }}
            min="1"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SiteMediaTable;

/*
class SiteMediaTable extends Component {
  state = {
    //    siteMedias: this.props.siteMedias,
    handleSMTableClick: this.props.handleSMTableClick,
    handleSMTableDeleteClick: this.props.handleSMTableDeleteClick,
  };
  render() {
    const columns = [
      {
        dataField: "name",
        text: "Naam",
        filter: textFilter(),
      },
      {
        dataField: "type",
        text: "Type",
      },
      {
        dataField: "url",
        text: "Url",
      },
      {
        text: "Image",
        formatter: (cell, media, rowIndex) => {
          return (
            <div style={{ width: "400px" }}>
              <Media media={media} roundImage={false}></Media>
            </div>
          );
        },
      },
      {
        formatter: (cell, row, rowIndex) => {
          return (
            <div>
              <Button
                onClick={() => this.state.handleSMTableClick(row, rowIndex)}
              >
                Selecteer
              </Button>

              <Button
                onClick={() =>
                  this.state.handleSMTableDeleteClick(row, rowIndex)
                }
              >
                Verwijder
              </Button>
            </div>
          );
        },
      },
    ];
    /*    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        //      onDoubleClick: (e, row, rowIndex) => {
        if (this.state.handleSMTableClick !== undefined) {
          this.state.handleSMTableClick(row._id, row);
          //        } else {
          //          this.setState({ redirect: `/organization/${row._id}` });
        }
      },
    };

  render() {
    return <div />;
  } /*
    return (
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={this.props.siteMedias}
        columns={columns}
        striped
        hover
        condensed
        noDataIndication={indication}
        pagination={paginationFactory()}
        filter={filterFactory()}
        //        rowEvents={rowEvents}
      ></BootstrapTable>
    ); 
}*/
/*
        cellEdit={cellEditFactory({ mode: "click", blurToSave: true })} 
*/
