import React from "react";
import { useState, useEffect } from "react";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import { Row, Col } from "react-bootstrap";

import SessionTable from "./sessionTable";
import SessionForm from "./sessionForm";
import SessionSiteTable from "./sessionSiteTable";
import SessionDatesTable from "./sessionDatesTable";

import { getSessions } from "../../../services/sessionService";

const Session = ({
  sessionSerieId,
  sessionSeries,
  session,
  sessions,
  setSessionId,
  setSession,
  setSessions,
  surveyList,
  setSurveyList,
}) => {
  const [activeSessionTab, setActiveSessionTab] = useState("editNotes");
  const [loadingSessions, setLoadingSessions] = useState(true);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [updateSessionTable, setUpdateSessionTable] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingSessions(true);
      const { data: sessionsDb } = await getSessions(
        "name datetime contact sessionSerieId"
      );
      setSessions(sessionsDb);
      filterSessions();
      setLoadingSessions(false);
    };
    fetchData();
  }, [updateSessionTable]);

  useEffect(() => {
    filterSessions();
  }, [sessionSerieId]);

  const filterSessions = () => {
    if (!sessionSerieId) {
      setFilteredSessions({ ...sessions });
    } else if (sessionSerieId) {
      setFilteredSessions(
        sessions.filter((s) => s.sessionSerieId === sessionSerieId)
      );
    }
  };

  const setSitePageObject = (sitePageObjectId, name) => {
    setSession({
      ...session,
      sitePageObjectId: sitePageObjectId,
      sitePageObjectName: name,
    });
  };

  //  if (!loadingSessions) return <div />;
  return (
    <>
      <Row>
        <SessionTable sessions={filteredSessions} setSessionId={setSessionId} />
        <Col className="sm-7">
          <SessionForm
            session={session}
            setSession={setSession}
            sessionSerieId={sessionSerieId}
            sessionSeries={sessionSeries}
            surveyList={surveyList}
            setSurveyList={setSurveyList}
            setUpdateSessionTable={setUpdateSessionTable}
          />
        </Col>
        <Col className="sm-5">
          <Tabs
            id="sessionTab"
            activeKey={activeSessionTab}
            onSelect={(k) => setActiveSessionTab(k)}
          >
            {/*<Tab eventKey="editDates" title="Datum">
              <SessionDatesTable
                sessionSerieId={sessionSerieId}
                setSessionId={setSessionId}
              />
  </Tab> */}
            <Tab eventKey="editNotes" title="Notities"></Tab>
            <Tab eventKey="editContacts" title="Deelnemers"></Tab>
            {/*<Tab eventKey="editSite" title="Site">
              <SessionSiteTable setSitePageObject={setSitePageObject} />
</Tab>*/}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default Session;
