import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Container } from "react-bootstrap";
// import { withRouter } from "react-router";

// import PageTop from "./page/pageTop";
import PageListItem from "./page/pageListItem";

import {
  getSitePageObject,
  //  saveSitePageObject,
} from "./../services/sitePageObjectService";
import { getSessions } from "../services/sessionService";
//import { saveContact } from "./../services/contactService";

import RegisterForSessionForm from "./registerForSessionForm";
import { getSessionSeries } from "../services/sessionSerieService";

const RegisterForSession = () => {
  const [sitePageObject, setSitePageObject] = useState(null);
  const [sitePageObjectId, setSitePageObjectId] = useState(null);
  const [sessionSeries, setSessionSeries] = useState(null);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  if (id !== sitePageObjectId) {
    setSitePageObjectId(id);
  }

  useEffect(() => {
    const fetchData = async (sitePageObjectId) => {
      const { data: sitePageObject } = await getSitePageObject(
        sitePageObjectId
      );
      setSitePageObject(sitePageObject);

      const { data: sessionSeriesDb } = await getSessionSeries(
        "name enrollStart enrollEnd",
        "sitePageObjectId=" + sitePageObjectId,
        "sessions"
      );
      if (sessionSeriesDb.length) setSessionSeries(sessionSeriesDb);
    };

    if (id && !sitePageObject) {
      fetchData(sitePageObjectId);
    }
  }, [id]);

  return (
    <React.Fragment>
      {/*}        <Page>   */}
      <Container>
        <h1 className="text-center">
          {sitePageObject && sitePageObject.title}
        </h1>
        {sitePageObject && (
          <>
            {/*<PageTop
              sitePageObjectId={sitePageObject._id}
              imagePos={sitePageObject.imagePos}
              imageSize={sitePageObject.imageSize}
              backgroundColor={sitePageObject.backgroundColor}
        />*/}
            <PageListItem
              sitePageObjectId={sitePageObjectId}
              fotoabove={false}
              imagePos={"right"}
              imageSize={5}
              backgroundColor={"white"}
            />
          </>
        )}

        <div className="row">
          <div className="col-12">
            <RegisterForSessionForm
              sessionSeries={sessionSeries}
              //              doSubmit={submitContactForm}
            />
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

// was withRouter(RegisterForSession)

export default RegisterForSession;
