import React, { Component } from "react";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";

class FormUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: null,
      loaded: 0,
      onFilesUploaded: this.props.onFilesUploaded,
    };
  }

  onChangeHandler = (event) => {
    //  console.log(event.target.files);
    this.setState({ selectedFiles: event.target.files, loaded: 0 });
  };

  onUploadHandler = () => {
    const data = new FormData();
    data.append("uploadtype", this.props.uploadtype);

    for (var x = 0; x < this.state.selectedFiles.length; x++) {
      data.append("file", this.state.selectedFiles[x]);
    }

    axios
      .post("/upload", data, {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then((res) => {
        // then print response status
        // (res.statusText);
      });

    if (this.state.onFilesUploaded) this.state.onFilesUploaded();
  };

  render() {
    return (
      <>
        <input
          type="file"
          className="form-control"
          multiple
          onChange={this.onChangeHandler}
        />
        <button
          type="button"
          className="btn btn-success btn-block"
          onClick={this.onUploadHandler}
        >
          Upload
        </button>
        <ProgressBar
          now={this.state.loaded}
          label={`${Math.round(this.state.loaded, 2)}%`}
        />
      </>
    );
  }
}

export default FormUpload;
