import React, { useEffect, useState } from "react";
/*
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
*/
import Media from "../../common/media";

import { getSitePageObjects } from "../../../services/sitePageObjectService";
/*
const columns = [
  {
    dataField: "name",
    text: "Naam",
    filter: textFilter(),
    headerStyle: (column, colIndex) => {
      return { width: "30%" };
    },
  },
  {
    dataField: "title",
    text: "Title",
    headerStyle: (column, colIndex) => {
      return { width: "40%" };
    },
  },
  {
    dataField: "image",
    text: "Image",
    headerStyle: (column, colIndex) => {
      return { width: "30%" };
    },
    formatter: (cell, row) => {
      if (!row.siteMedias) {
        return <div />;
      }
      const media = row.siteMedias[0];
      // console.log("media ", media);
      if (media && !media.type) {
        media.type = "image";
        // console.log("media modified", media);
      } else if (!media) {
        return <div />;
      }
      return (
        <React.Fragment>
          {media && (
            <div>
              <Media
                media={media}
                //                 src={`${process.env.REACT_APP_SRV_URL}/${row.siteMedia.path}`}
                roundImage={row.roundImage}
                className="tableImage"
              />
              {row.roundImage}
            </div>
          )}
        </React.Fragment>
      );
    },
  },
];
*/
const SessionSiteTable = ({ setSitePageObject }) => {
  /*  const [sitePageObjects, setSitePageObjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: sitePageObjectsDb } = await getSitePageObjects(
        "name elementType title siteMedias"
      );
      const spo = [];
      sitePageObjectsDb.map((o) => {
        if (o.elementType === "session") spo.push(o);
      });
      setSitePageObjects(spo);
    };
    fetchData();
  }, []);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setSitePageObject(row._id, row.name);
    },
  };
*/
  return <div />;
  /*
  return (
    <BootstrapTable
      bootstrap4
      keyField="_id"
      data={sitePageObjects}
      columns={columns}
      striped
      hover
      condensed
      pagination={paginationFactory()}
      filter={filterFactory()}
      rowEvents={rowEvents}
    ></BootstrapTable>
  );
  */
};

export default SessionSiteTable;
