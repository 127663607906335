import http from "./httpService";

const apiEndpoint = "/sitepageobjects";

function sitePageObjectUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getSitePageObjects(fields) {
  if (fields) {
    return http.get(apiEndpoint + "?fields=" + fields);
  } else return http.get(apiEndpoint);
}

export function getSitePageObject(sitePageObjectId) {
  return http.get(sitePageObjectUrl(sitePageObjectId));
}

export function saveSitePageObject(sitePageObject) {
  if (sitePageObject._id && sitePageObject._id !== "new") {
    const body = { ...sitePageObject };
    delete body._id;
    return http.put(sitePageObjectUrl(sitePageObject._id), body);
  }

  return http.post(apiEndpoint, sitePageObject);
}

export function deleteSitePageObject(sitePageObjectId) {
  return http.delete(sitePageObjectUrl(sitePageObjectId));
}
