import http from "./httpService";

const apiEndpoint = "/projects";

function projectUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getProjects(fields) {
  if (fields) {
    return http.get(apiEndpoint + "?fields=" + fields);
  } else return http.get(apiEndpoint);
}

export function getProject(projectId) {
  return http.get(projectUrl(projectId));
}

export function saveProject(project) {
  if (project._id && project._id !== "new") {
    const body = { ...project };
    delete body._id;
    return http.put(projectUrl(project._id), body);
  }

  return http.post(apiEndpoint, project);
}
/*
export function deleteSitePageObject(customerId) {
  return http.delete(sitePageObjectUrl(customerId));
}
*/
