import React from "react";
// import BootstrapTable from "react-bootstrap-table-next";

import {
  dateTimeToString,
  mySQLStringToDate,
  longDateTimeToString,
} from "./common/annaformats";

const RegisterForSessionTable = ({ sessions }) => {
  /*
  const sessionColumns = [
    {
      dataField: "name",
      text: "Naam",
      headerStyle: (column, colIndex) => {
        return { width: "40%" };
      },
    },
    {
      dataField: "datetime",
      text: "Datum tijd",
      formatter: (cell, row) => {
        var dt = cell;
        if (typeof dt === "Date" || typeof dt === "object") {
          const dtstr = dateTimeToString(dt);
          return <h6>{dtstr}</h6>;
        } else if (typeof dt === "string") {
          const dtstr = longDateTimeToString(mySQLStringToDate(dt));
          return <h6>{dtstr}</h6>;
        }
        //          }
      },
      headerStyle: (column, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "contact",
      text: "Sessie begeleider",
      headerStyle: (column, colIndex) => {
        return { width: "35%" };
      },
    },
  ];

  return (
    <BootstrapTable
      bootstrap4
      keyField="_id"
      data={sessions}
      columns={sessionColumns}
      striped
      hover
      condensed
    ></BootstrapTable>
  ); */
  return <div />;
};

export default RegisterForSessionTable;
