import http from "./httpService";

const apiEndpoint = "/sessions";

function sessionUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getSessions(fields, filter = null) {
  let url = apiEndpoint;
  if (fields) {
    url = url + "?fields=" + fields;
  }
  if (filter) {
    url = url + (fields ? "&" : "?") + "filter=" + filter;
  }
  return http.get(url);
  /*  if (fields) {
   return http.get(
      apiEndpoint + "?fields=" + fields + filter ? "&filter=" + filter : ""
    );
  } else return http.get(apiEndpoint);*/
}

export function getSession(sessionId) {
  return http.get(sessionUrl(sessionId));
}

export function saveSession(session) {
  if (session._id && session._id !== "new") {
    const body = { ...session };
    delete body._id;
    return http.put(sessionUrl(session._id), body);
  }

  return http.post(apiEndpoint, session);
}
/*
export function deleteSitePageObject(customerId) {
  return http.delete(sitePageObjectUrl(customerId));
}
*/
