import http from "./httpService";

const apiEndpoint = "/surveyresults";

function surveyResultUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getSurveyResults(fields) {
  if (fields) {
    return http.get(apiEndpoint + "?fields=" + fields);
  } else return http.get(apiEndpoint);
}

export function getSurveyResult(surveyId) {
  return http.get(surveyResultUrl(surveyId));
}

export function saveSurveyResult(surveyResult) {
  if (surveyResult._id && surveyResult._id !== "new") {
    const body = { ...surveyResult };
    delete body._id;
    return http.put(surveyResultUrl(surveyResult._id), body);
  }

  return http.post(apiEndpoint, surveyResult);
}
/*
export function deleteSitePageObject(customerId) {
  return http.delete(sitePageObjectUrl(customerId));
}
*/
