import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
//import * as userService from "../services/userService";
import auth from "../services/authService";

import FormTextInput from "./common/formTextInput";

const LoginForm = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});

  /* PM: dit is joi format, moet yup worden 
  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };
*/
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/nl/home");
  };
  if (auth.getCurrentUser()) handleClick();

  const doSubmit = async (data) => {
    try {
      //     const { data } = this.state;
      const li = await auth.login(data.email, data.password);
      handleClick();
      //      const { state } = this.props.location;
      //      window.location = state ? state.from.pathname : "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        //        const errors = { ...this.state.errors };
        //        errors.username = ex.response.data;
        alert("Fout: Email adres en/of wachtwoord fout");
        setErrors(errors);
      }
    }
  };

  return (
    <div>
      <h1>Login</h1>
      <Formik
        initialValues={data}
        enableReinitialize
        validationSchema={Yup.object({
          //name: Yup.string().required("Vereist"),
          //value: Yup.string().required("Vereist"),
          //            typename: Yup.number().min(1).required("Kies"),
        })}
        onSubmit={async (values) => {
          doSubmit(values);
        }}
      >
        <div className="container">
          <Form>
            <div className="row">
              <div className="col-6">
                {/* <!--left side --> */}
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Email"
                    name="email"
                    type="text"
                  />
                </div>
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Wachtwoord"
                    name="password"
                    type="password"
                  />
                </div>

                <button type="submit">Login</button>
              </div>
            </div>
          </Form>
        </div>
      </Formik>
    </div>
  );
};

export default LoginForm;
