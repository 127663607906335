import React, { useState, useEffect } from "react";
/*
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
*/
import FormTextInput from "../common/formTextInput";
import FormDateTimeInput from "../common/formDateTimeInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MdDeleteOutline } from "react-icons/md";
import { dateTimeToString, mySQLStringToDate } from "../common/annaformats";
import _ from "lodash";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const SessionForm = (props) => {
  /*
  //  state = {
  //    sessionDates: this.props.sessionDates,
  //   doSubmit: this.props.doSubmit,
  //  };
  const sitePageObject = props.sitePageObject;
  const doSubmit = props.doSubmit;
  const doUpdateSitePageObject = props.doUpdateSitePageObject;

  useEffect(() => {
    console.log("rernder");
  }, [sitePageObject.sessionDates]);

  function buttonsformatter(cell, row) {
    // (cell, row, rowIndex) => {
    return (
      <MdDeleteOutline
        onClick={() => {
          //          alert(row);
          deleteRow(row);
        }}
      />
    );
  }

  const sortRows = () => {
    sitePageObject.sessionDates.sort(function (a, b) {
      return new Date(a.datetime) - new Date(b.datetime);
    });
    if (!sitePageObject.sessionDates[sitePageObject.sessionDates.length - 1])
      sitePageObject.sessionDates.pop();
    const sd = sitePageObject.sessionDates.slice(0);
    sitePageObject.sessionDates = sd;
  };

  const deleteRow = (row) => {
    for (var i = 0; i < sitePageObject.sessionDates.length; i++) {
      if (sitePageObject.sessionDates[i].datetime === row.datetime) {
        delete sitePageObject.sessionDates[i];
        break;
      }
    }
    sortRows();
    doUpdateSitePageObject(sitePageObject.sessionDates);
    //    setSitePageObject(sitePageObject);
  };

  const addRows = (values) => {
    for (var i = 0; i < values.repeat; i++) {
      const date = new Date(Date.parse(values.date));
      date.setDate(date.getDate() + i * 7);
      sitePageObject.sessionDates.push({
        datetime: date.toJSON(),
        maxParticipants: values.maxParticipants,
      });
      sortRows();
    }
    doUpdateSitePageObject(sitePageObject.sessionDates);
    // setSitePageObject(sitePageObject);
  };

  //  render() {
  const columns = [
    {
      dataField: "datetime",
      text: "Aanvang",
      formatter: (cell, row) => {
        var dt = cell;
        if (typeof dt === "Date" || typeof dt === "object") {
          const dtstr = dateTimeToString(dt);
          return <h6>{dtstr}</h6>;
        } else if (typeof dt === "string") {
          const dtstr = dateTimeToString(mySQLStringToDate(dt));
          return <h6>{dtstr}</h6>;
        }
        //          }
      },
    },
    {
      dataField: "maxParticipants",
      text: "Aantal deelnemers",
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Numeriek veld",
          };
        }
        return true;
      },
    },
    {
      editable: false,
      formatter: buttonsformatter,
    },
  ]; // onClick={() => remove(rowIndex)}
  //    <MdDeleteOutline fill="blue" size="30" />;

  return (
    <div>
      <br />
      <h2>Agenda sessies {sitePageObject.name}</h2>
      <br />
      <br />
      <Formik
        enableReinitialize
        initialValues={{ date: "", time: "", repeat: 0 }}
        validationSchema={Yup.object({
          //name: Yup.string().required("Vereist"),
          //value: Yup.string().required("Vereist"),
          //            typename: Yup.number().min(1).required("Kies"),
        })}
        //        onSubmit={async (values) => {
        //          console.log("+");
        //        }}
      >
        {({ handleChange, handleBlur, setFieldValue, values }) => (
          <div className="container">
            <Form>
              <button
                onClick={() => {
                  doSubmit(values);
                }}
              >
                Opslaan
              </button>
              <br />
              <br />
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={sitePageObject.sessionDates}
                columns={columns}
                striped
                hover
                condensed
                //noDataIndication={indication}
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                })}
              ></BootstrapTable>

              <div className="row">
                <div className="col-6">
                  {/* <!--left side --> * /}
                  <div className="form-group row">
                    <FormDateTimeInput
                      className="formLabel"
                      label="Datum (dd-mm-yy)"
                      name="date"
                      type="text"
                      format="dd-MM-yyyy HH:mm"
                      //onChange={(e) => {
                      //  this.setState({ date: e.target.value });
                      //}}
                    />
                  </div>
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Max aantal deelnemers"
                      name="maxParticipants"
                      type="numeric"
                      //onChange={(e) => {
                      //  this.setState({ time: e.target.value });
                      //}}
                    />
                  </div>
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Herhaal (aantal weken)"
                      name="repeat"
                      type="numeric"
                      //onChange={(e) => {
                      //  this.setState({ repeat: e.target.value });
                      //}}
                    />
                  </div>
                  <br />
                  <button
                    onClick={() => {
                      addRows(values);
                    }}
                  >
                    Toevoegen
                  </button>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
  */
  return <div />;
};
// }
/*
      <SiteSessionDatesTable
        sessionDates={this.state.sitePageObject.sessionDates}
      />
*/
export default SessionForm;
