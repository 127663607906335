import React from "react";
import FormSelect from "./formSelect";

const FormSelectList = ({ label, list, name, ...props }) => {
  //  console.log("label, name, list ", label, name, list);
  return (
    <FormSelect label={label} name={name} {...props}>
      {list.options &&
        list.options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.name}
          </option>
        ))}
    </FormSelect>
  );
};

export default FormSelectList;
