import { Button } from "react-bootstrap";
import { useFormikContext } from "formik";

const SubmitButton = ({ title }) => {
  const { handleSubmit, isValid, values } = useFormikContext();
  return (
    <>
      <Button disabled={!isValid} onClick={handleSubmit}>
        {title}
      </Button>
      {values.name}
    </>
  );
};

export default SubmitButton;
