import React, { Component } from "react";
import { Container } from "react-bootstrap";
// import { withRouter } from "react-router";

import PageTop from "./page/pageTop";
import PageListItem from "./page/pageListItem";
import { getSitePageDefinitionOnName } from "./../services/sitePageDefinitionService";
import { saveContact, emptyContact } from "./../services/contactService";

import ContactForm from "./contactForm";

class Contact extends Component {
  state = {
    sitePageDefinition: null,
    name: "",
    title: "laden",
    sitePageTopObjects: [],
    sitePageObjects: [],
    contact: emptyContact(),
  };

  populateHome = async () => {
    //    if (!sitePageDefinition) {
    //    const path = this.props.match.path;
    const language = "nl"; // path.substring(1, path.lastIndexOf("/"));
    const page = "contact"; //this.props.match.params.page;
    const { data: sitePageDefinition } = await getSitePageDefinitionOnName(
      language,
      page
    );
    //    }
    this.setState({ sitePageDefinition });
    this.setState({ title: sitePageDefinition.title });
    const sitePageObjects = sitePageDefinition.sitePageObjects.slice();
    const sitePageTopObjects = sitePageObjects.splice(0, 1);
    this.setState({ sitePageTopObjects });
    this.setState({ sitePageObjects });
  };

  async componentDidMount() {
    await this.populateHome(null);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match) {
      await this.populateHome(null);
    } /*else if (
      this.props.sitePageDefinition &&
      this.props.sitePageDefinition !== this.state.sitePageDefinition
    ) {
      await this.populateHome(this.props.sitePageDefinition);
    } */
  }

  // LET OP : Preview in editInfoSite is bijna copy van Home

  submitContactForm = async (values) => {
    const contact = values;
    // console.log(JSON.stringify(contact));
    const { data: contactDb } = await saveContact(contact);
    // console.log("\nSave contact DB\n");
    // console.log(JSON.stringify(contactDb));
    this.setState({ contact: contactDb });
  };

  render() {
    const sitePageDefinition = this.state.sitePageDefinition;
    const sitePageTopObjects = this.state.sitePageTopObjects;
    const sitePageObjects = this.state.sitePageObjects;
    /*    
    let nrofcolumns =
      sitePageDefinition && sitePageDefinition.nrofcolumns
        ? parseInt(sitePageDefinition.nrofcolumns)
        : 1;
    let columnclassname = "";
    let spoMc = [];

    if (nrofcolumns > 1) {
      if (nrofcolumns > 4) nrofcolumns = 4;

      const width = 12 / nrofcolumns;
      columnclassname = `("col-lg-${width} col-md-${width} col-sm-${width} col-xs-12")`;

      for (let i = 0; i < sitePageObjects.length; i++) {
        const spo = sitePageObjects[i];
        spo.rowstartdiv = i % nrofcolumns === 0 ? '<div className="row">' : "";
        spo.rowenddiv =
          (i + 1) % nrofcolumns === 0 || i === nrofcolumns - 1 ? "</div" : "";
        spoMc.push(spo);
      }
    }
*/
    return (
      <React.Fragment>
        {/*}        <Page>   */}
        <Container>
          <br />
          <h1 className="text-center">{this.state.title}</h1>
          <br />
          {sitePageTopObjects.length && (
            <PageTop
              sitePageObjectId={sitePageTopObjects[0]._id}
              imagePos={sitePageTopObjects[0].imagePos}
              imageSize={sitePageTopObjects[0].imageSize}
              backgroundColor={sitePageTopObjects[0].backgroundColor}
            />
          )}
          <br />
          <div className="row">
            <div className="col-4">
              {sitePageObjects.length &&
                sitePageObjects.map((o) => (
                  <PageListItem
                    fotoabove={true}
                    sitePageObjectId={o._id}
                    imagePos={o.imagePos}
                    imageSize={o.imageSize}
                    backgroundColor={o.backgroundColor}
                  />
                ))}
            </div>
            <div className="col-8">
              <ContactForm
                contact={this.state.contact}
                doSubmit={this.submitContactForm}
              />
            </div>
          </div>
        </Container>
        {/*}        </Page>  */}
      </React.Fragment>
    );
  }
}
// was withRouter(Contact)

export default Contact;
