import React, { useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

import FormTextInput from "../../common/formTextInput";
import FormTextArea from "../../common/formTextArea";
import TextEditor from "../../common/textEditor2";

import { saveSurvey } from "../../../services/surveyService";
//  const linkTo = "/project?id=" + projectId; parameter optioneel

const SurveyForm = ({ survey, setSurvey }) => {
  const [localSurvey, setLocalSurvey] = useState({
    description: "",
  });

  const doSubmit = async (values) => {
    const survey = { ...values };
    const { data: surveyDb } = await saveSurvey(survey);
    setSurvey(surveyDb);
  };

  if (survey && survey !== localSurvey) {
    setLocalSurvey(survey);
  } else if (!survey && localSurvey._id) {
    const c = { ...localSurvey };
    for (let k in c) {
      c[k] = "";
    }
    setLocalSurvey(c);
  }
  useFormikContext();

  return (
    <>
      <h2>Enquete</h2>
      <Formik
        enableReinitialize
        initialValues={localSurvey}
        validationSchema={Yup.object({
          name: Yup.string().required("Vereist"),
          //value: Yup.string().required("Vereist"),
          //            typename: Yup.number().min(1).required("Kies"),
        })}
        onSubmit={async (values) => {
          doSubmit(values);
        }}
      >
        {({ handleChange, handleBlur, setFieldValue, values }) => (
          <div className="container">
            <Form>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Nummer"
                    name="nr"
                    type="text"
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Naam"
                    name="name"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group row">
                  <FormTextArea
                    className="formLabel"
                    label="Survey Json"
                    name="surveyJson"
                    type="text"
                  />
                </div>
              </div>
              <a
                href="https://surveyjs.io/form-library/examples/survey-logo/reactjs"
                target="_blank"
              >
                Voorbeelden van surveys
              </a>
              <br />
              <a href="https://jsonlint.com/" target="_blank">
                Fout in json? kijk hier
              </a>
              <div className="row" style={{ marginTop: "15px" }}>
                <div className="form-group row">
                  <h6>Omschrijving</h6>
                  <TextEditor
                    setFieldValue={(val) => setFieldValue("description", val)}
                    value={values.description}
                    name="text"
                  />
                </div>
              </div>
              <br />
              <br />
              <Button type="submit">Opslaan</Button>
              <br />
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default SurveyForm;
