import React, { useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";

import FormTextInput from "../../common/formTextInput";
import TextEditor from "../../common/textEditor2";
import { Button } from "react-bootstrap";

import { saveCustomer } from "../../../services/customerService";
//  const linkTo = "/customer?id=" + customerId; parameter optioneel

const CustomerForm = ({ customer, setCustomer }) => {
  const [localCustomer, setLocalCustomer] = useState({ description: "" });

  const doSubmit = async (values) => {
    const customer = values;
    const { data: customerDb } = await saveCustomer(customer);
    setCustomer(customerDb);
  };

  if (customer && customer !== localCustomer) {
    setLocalCustomer(customer);
  } else if (!customer && localCustomer._id) {
    const c = { ...localCustomer };
    for (let k in c) {
      c[k] = "";
    }
    setLocalCustomer(c);
  }

  useFormikContext();

  return (
    <>
      <h2>Klanten</h2>
      <Formik
        enableReinitialize={true}
        initialValues={localCustomer}
        validationSchema={Yup.object({
          name: Yup.string().required("Vereist"),
          //value: Yup.string().required("Vereist"),
          //            typename: Yup.number().min(1).required("Kies"),
        })}
        onSubmit={async (values) => {
          doSubmit(values);
        }}
      >
        {({ handleChange, handleBlur, setFieldValue, values }) => (
          <div className="container">
            <Form>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Nummer"
                    name="nr"
                    type="text"
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Naam"
                    name="name"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Contact persoon"
                    name="contact"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Email"
                    name="email"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    setFieldValue={(val) => setFieldValue("phone", val)}
                    className="formLabel"
                    label="Telefoonnummer"
                    name="phone"
                    type="text"
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: "15px" }}>
                <div className="form-group row">
                  <h6>Opmerkingen</h6>
                  <TextEditor
                    setFieldValue={(val) => setFieldValue("description", val)}
                    value={values.description}
                    name="text"
                  />
                </div>
              </div>

              <br />
              <br />
              <Button type="submit">Opslaan</Button>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default CustomerForm;
