import http from "./httpService";

const apiEndpoint = "/contacts";

function contactUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function emptyContact() {
  const contact = {
    name: "",
    email: "",
    phonenr: "",
    method: "",
    phoneable: "",
    subject: "",
    question: "",
  };
  return contact;
}

export function getContact(contactId) {
  return http.get(contactUrl(contactId));
}

export function getContacts(fields, filter = null, type = null) {
  let url = apiEndpoint;
  let connect = "?";

  if (fields) {
    url = url + "?fields=" + fields;
    connect = "&";
  }
  if (filter) {
    url = url + connect + filter;
    connect = "&";
  }
  if (type) {
    url = url + connect + "type=" + type;
  }

  return http.get(url);
}
/*
export function getSitePageObjects(fields) {
  if (fields) {
    return http.get(apiEndpoint + "?fields=" + fields);
  } else return http.get(apiEndpoint);
}

export function getSitePageObject(sitePageObjectId) {
  return http.get(sitePageObjectUrl(sitePageObjectId));
}
*/
export function saveContact(contact) {
  if (contact._id && contact._id !== "new") {
    const body = { ...contact };
    delete body._id;
    return http.put(contactUrl(contact._id), body);
  }

  return http.post(apiEndpoint, contact);
}
/*
export function deleteSitePageObject(sitePageObjectId) {
  return http.delete(sitePageObjectUrl(sitePageObjectId));
}
*/
