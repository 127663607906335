import React, { useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
// import { Button } from "react-bootstrap";

import FormTextInput from "../../common/formTextInput";
import FormTextInputSearch from "../../common/formTextInputSearch";
import FormSelectList from "../../common/formSelectList";
import TextEditor from "../../common/textEditor2";
import FormDateTimeInput from "../../common/formDateTimeInput";
import SubmitButton from "../../common/submitButton";

import { saveSession } from "../../../services/sessionService";

//  const linkTo = "/project?id=" + projectId; parameter optioneel

const SessionForm = ({
  session,
  setSession,
  sessionSerieId,
  sessionSeries,
  surveyList,
  setSurveyList,
  setUpdateSessionTable,
}) => {
  const [localSession, setLocalSession] = useState({
    description: "",
  });

  const doSubmit = async (values) => {
    const session = {
      ...values,
      sessionSerieId: sessionSerieId,
    };

    const { data: sessionDb } = await saveSession(session);
    setSession(sessionDb);

    if (values.weeksToRepeat) {
      const date = new Date(Date.parse(values.datetime));
      for (var i = 1; i < values.weeksToRepeat; i++) {
        const nextDate = new Date(date.setDate(date.getDate() + i * 7));
        const sessionLoop = { ...session, _id: "new" };
        sessionLoop.datetime = nextDate;
        // const { data: sessionDb } =
        await saveSession(sessionLoop);
      }
    }
    setUpdateSessionTable(
      (prevUpdateSessionTable) => prevUpdateSessionTable + 1
    );
  };

  const findSessionSerieName = (ssId) => {
    if (!ssId) return "";
    if (!sessionSeries) return "";
    const ss = sessionSeries.find((ss) => ss._id === ssId);
    if (ss && ss.name) return ss.name;
  };

  useEffect(() => {
    if (session && session !== localSession) {
      // todo deze test is fout!!!
      const sessionSerieName = findSessionSerieName(session.sessionSerieId);
      setLocalSession({ ...session, sessionSerieName: sessionSerieName });
    } else if (!session && localSession._id) {
      const s = { ...localSession };
      for (let k in s) {
        s[k] = "";
      }
      setLocalSession(s);
    } else if (!session && sessionSerieId) {
      const s = {
        sessionSerieId: sessionSerieId,
        sessionSerieName: findSessionSerieName(sessionSerieId),
      };
      setLocalSession(s);
    }
  }, [sessionSerieId, session]);

  useFormikContext();

  if (!localSession) {
    return <div />;
  } else {
    return (
      <>
        <h2>Sessie</h2>
        <Formik
          enableReinitialize
          initialValues={localSession}
          validationSchema={Yup.object({
            name: Yup.string().required("Vereist"),
            datetime: Yup.string().required("Vereist"),
            //value: Yup.string().required("Vereist"),
            //            typename: Yup.number().min(1).required("Kies"),
          })}
          onSubmit={async (values) => {
            doSubmit(values);
          }}
        >
          {({ handleChange, handleBlur, setFieldValue, values }) => (
            <div className="container">
              <Form>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Nummer"
                      name="nr"
                      type="text"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInputSearch
                      label="Sessie serie"
                      name="sessionSerieName"
                      disabled={true}
                      onClick={() => alert("nog niet geimplementeerd")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Naam"
                      name="name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormDateTimeInput
                      className="formLabel"
                      label="Datum tijd (dd-mm-yyyy hh:mm )"
                      name="datetime"
                      type="text"
                      format="dd-MM-yyyy HH:mm"
                      //onChange={(e) => {
                      //  this.setState({ date: e.target.value });
                      //}}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Aantal weken herhalen"
                      name="weeksToRepeat"
                      type="number"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Sessieleider naam"
                      name="contact"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Email"
                      name="email"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Telefoonnummer"
                      name="phone"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormSelectList
                      label="Enquete voor sessie"
                      list={surveyList}
                      name="preSessionSurveyId"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormSelectList
                      label="Enquete na sessie"
                      list={surveyList}
                      name="postSessionSurveyId"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      label="Site koppeling"
                      name="sitePageObjectName"
                      className="formLabel"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="form-group row">
                    <h6>Omschrijving</h6>
                    <TextEditor
                      setFieldValue={(val) => setFieldValue("description", val)}
                      value={values.description}
                      name="text"
                    />
                  </div>
                </div>
                <br />
                <br />
                <SubmitButton title="Opslaan" />
                <br />
              </Form>
            </div>
          )}
        </Formik>
      </>
    );
  }
};

export default SessionForm;
