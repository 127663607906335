import axios from "axios";
//import logger from "./logService";
//import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

console.log("baseurl api ", process.env.REACT_APP_API_URL);
// console.log("baseurl srv ", process.env.REACT_APP_SRV_URL);
for (const [key, value] of Object.entries(process.env)) {
  console.log(`${key}: ${value}`);
}

console.log("baseurl api ", process.env);

axios.interceptors.response.use(
  (response) => {
    //console.log("response", response);
    //    toast.info(response);

    return response;
  },
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      // console.log("unexpected error in httpService ");
      //      logger.log(error);
      //      toast.error("An unexpected error occurrred.");
    }

    return Promise.reject(error);
  }
);

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
