import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
// import { Link } from "react-router-dom";

class NavBar2 extends Component {
  state = {
    dropdownOpen: false,
    dropdownMenu: "",
    user: this.props.user,
    projects: this.props.projects,
  };

  componentDidUpdate(prevProps) {
    if (this.props.user && prevProps.user !== this.props.user) {
      this.setState({ user: this.props.user });
    }
    if (this.props.projects && prevProps.projects !== this.props.projects) {
      this.setState({ projects: this.props.projects });
    }
  }

  render() {
    const { user } = this.state;
    const { projects } = this.state;

    /* <nav className="navbar navbar-expand-lg navbar-light bg-light">  */

    return (
      <Container className="justify-content-md-right" bg="#F1EFE4">
        <Navbar
          collapseOnSelect
          expand="sm"
          bg="#21EFE4"
          className="justify-content-end"
          fixedt="top"
        >
          <Container>
            <Navbar.Brand href="#home">
              <Image
                src={`${process.env.REACT_APP_SRV_URL}/sitemedia/logo-anna.png`}
                className="tableImage centerImage"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="navbar-collapse"
              className="justify-content-end"
            >
              <Nav className="mr-auto">
                <Nav.Link href="/home">Home</Nav.Link>
                <NavDropdown title="Over ons" id="wie-nav-dropdown">
                  <NavDropdown.Item href="/anna">Anna</NavDropdown.Item>
                  <NavDropdown.Item href="/wie">Team</NavDropdown.Item>
                  <NavDropdown.Item href="/contact">Contact</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Projecten" id="basic-nav-dropdown">
                  {projects &&
                    projects.length &&
                    projects.map((project) => (
                      <NavDropdown.Item href={project.href} key={project.nr}>
                        {project.menuname}
                      </NavDropdown.Item>
                    ))}
                  {/* <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item> */}
                </NavDropdown>
                <NavDropdown title="Nieuws" id="news-nav-dropdown">
                  <NavDropdown.Item href="/nieuws">Actueel</NavDropdown.Item>
                  <NavDropdown.Item href="/geschiedenis">
                    Geschiedenis
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Inschrijven" id="wie-nav-dropdown">
                  {user && user.userType && user.userType.isSiteAdmin && (
                    <NavDropdown.Item href="/sessie">
                      Als sessiedeelnemer
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item href="/vrijwiliger">
                    Als ( vrijwillige ) sessieleider
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Ondersteuning"
                  id="ondersteuning-nav-dropdown"
                >
                  <NavDropdown.Item href="/ondersteuning">
                    Fondsen
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/donaties">Donaties</NavDropdown.Item>
                  <NavDropdown.Item href="/overig">Overig</NavDropdown.Item>
                </NavDropdown>

                {/*}            <Nav.Link href="steunons">Steun ons</Nav.Link> 
                <Nav.Link href="contact">Contact</Nav.Link>*/}
                <NavDropdown
                  title={!user ? "Login" : user.name}
                  id="login-nav-dropdown"
                >
                  {!user && (
                    <React.Fragment>
                      <NavDropdown.Item href="/login">Login</NavDropdown.Item>
                      <NavDropdown.Item href="/register">
                        Registreren
                      </NavDropdown.Item>
                    </React.Fragment>
                  )}
                  {user && user.userType && user.userType.isSiteAdmin && (
                    <>
                      <NavDropdown.Item href="/editinfosite">
                        Edit site
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/editsessions">
                        Edit sessies ( facultatief )
                      </NavDropdown.Item>
                    </>
                  )}
                  {user && (
                    <React.Fragment>
                      <NavDropdown.Item href="/profile">
                        Profiel
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                    </React.Fragment>
                  )}
                </NavDropdown>
                {user && user.userType && user.userType.isSiteAdmin && (
                  <NavDropdown title="Back office" id="backoffice-nav-dropdown">
                    <NavDropdown.Item href="/editcustomers">
                      Klanten en projecten
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/survey">Enquetes</NavDropdown.Item>
                    <NavDropdown.Item href="/enqueteresultinput">
                      Uitgevoerde enquete invoeren
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/enqueteresultedit">
                      Uitgevoerde enquete aanpassen
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/enqueteedit">
                      Enquete onderhoud
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
    );
  }
}

export default NavBar2;
