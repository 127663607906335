import React, { Component } from "react";
import { Container } from "react-bootstrap";
// import { withRouter } from "react-router";

import PageTop from "./page/pageTop";
import PageListItem from "./page/pageListItem";
import { getSitePageDefinitionOnName } from "./../services/sitePageDefinitionService";

class Home extends Component {
  state = {
    user: this.props.user,
    sitePageDefinition: null,
    name: "",
    title: "",
    sitePageTopObjects: [],
    sitePageObjects: [],
    imagesize: 12,
    //    onPageEdit: false,
  };

  populateHome = async () => {
    //    if (!sitePageDefinition) {
    // const path = this.props.match.path;
    const language = "nl"; // path.substring(1, path.lastIndexOf("/"));
    const page = this.props.page;
    //    const onPageEdit = this.props.onPageEdit;
    const { data: sitePageDefinition } = await getSitePageDefinitionOnName(
      language,
      page
    );
    //    }
    this.setState({ sitePageDefinition });
    this.setState({ title: sitePageDefinition.title });
    const sitePageObjects = sitePageDefinition.sitePageObjects.slice();
    const sitePageTopObjects = sitePageObjects.splice(0, 1);
    this.setState({ sitePageTopObjects });
    this.setState({ sitePageObjects });
    //    this.setState({ onPageEdit });
  };

  async componentDidMount() {
    await this.populateHome(null);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match) {
      await this.populateHome(null);
    }
    //    if (prevProps.onPageEdit !== this.props.onPageEdit) {
    //      this.setState({ onPageEdit: this.props.onPageEdit });
    //    }
    /*else if (
      this.props.sitePageDefinition &&
      this.props.sitePageDefinition !== this.state.sitePageDefinition
    ) {
      await this.populateHome(this.props.sitePageDefinition);
    } */
  }

  // LET OP : Preview in editInfoSite is bijna copy van Home

  render() {
    const sitePageDefinition = this.state.sitePageDefinition;
    const sitePageTopObjects = this.state.sitePageTopObjects;
    const sitePageObjects = this.state.sitePageObjects;
    let nrofcolumns =
      sitePageDefinition && sitePageDefinition.nrofcolumns
        ? parseInt(sitePageDefinition.nrofcolumns)
        : 1;
    let columnclassname = "";
    let spoMc = [];

    if (nrofcolumns > 1) {
      if (nrofcolumns > 4) nrofcolumns = 4;

      const width = 12 / nrofcolumns;
      columnclassname = `("col-lg-${width} col-md-${width} col-sm-${width} col-xs-12")`;

      for (let i = 0; i < sitePageObjects.length; i++) {
        const spo = sitePageObjects[i];
        spo.rowstartdiv = i % nrofcolumns === 0 ? '<div className="row">' : "";
        spo.rowenddiv =
          (i + 1) % nrofcolumns === 0 || i === nrofcolumns - 1 ? "</div" : "";
        spoMc.push(spo);
      }
    }

    return (
      <>
        {/*}        <Page>   */}
        <Container>
          <br />
          <h1 className="text-center">
            {this.state.title}{" "}
            {/*this.state.onPageEdit === true && " on"}
            {this.state.onPageEdit === false && " off"*/}
          </h1>
          {/* <!--- br ---/>   */}
          <div className="row">
            {sitePageTopObjects.length > 0 && (
              <PageTop
                sitePageObjectId={sitePageTopObjects[0]._id}
                imagePos={sitePageTopObjects[0].imagePos}
                imageSize={sitePageTopObjects[0].imageSize}
                backgroundColor={sitePageTopObjects[0].backgroundColor}
                user={this.state.user}
              />
            )}
            {nrofcolumns < 2 &&
              sitePageObjects.length > 0 &&
              sitePageObjects.map((o, index) => (
                <>
                  <PageListItem
                    key={o._id}
                    fotoabove={false}
                    sitePageObjectId={o._id}
                    imagePos={o.imagePos}
                    imageSize={o.imageSize}
                    backgroundColor={o.backgroundColor}
                    user={this.state.user}
                  />
                </>
              ))}

            {nrofcolumns > 1 &&
              spoMc.length > 0 &&
              spoMc.map((o, index) => (
                <div
                  className={columnclassname}
                  data-aos="fade-right"
                  key={index}
                >
                  <PageListItem
                    key={o._id}
                    fotoabove={true}
                    sitePageObjectId={o._id}
                    imagePos={o.imagePos}
                    imageSize={o.imageSize}
                    backgroundColor={o.backgroundColor}
                    user={this.state.user}
                  />
                </div>
              ))}
          </div>
        </Container>
      </>
    );
  }
}
// was withRouter(Home)
export default Home;
