import React, { Component } from "react";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import { Row } from "react-bootstrap";
import _ from "lodash";
import SitePagesDefinitionTable from "./sitePagesDefinitionTable";
import SitePageObjectsTable from "./sitePageObjectsTable";
//import SitePageObjectsTable from "./sitePageObjectsTable2";
import Button from "@restart/ui/esm/Button";
import SitePageDefinitionForm from "./sitePageDefinitionForm";
import SitePageObjectForm from "./sitePageObjectForm";
import SiteMediaTable from "./siteMediaTable";
import PageListItemEdit from "./../page/pageListItemEdit";
import SiteMediaForm from "./siteMediaForm";
import FormUpload from "../common/formUpload";
import EditImage from "./../common/editImage";
// import PreviewPage from "./previewPage";

import {
  getSitePageDefinitions,
  saveSitePageDefinition,
} from "../../services/sitePageDefinitionService";
import {
  getSitePageObjects,
  saveSitePageObject,
} from "../../services/sitePageObjectService";

import {
  getSiteMedias,
  saveSiteMedia,
  deleteSiteMedia,
} from "../../services/siteMediaService";

class EditInfoSite extends Component {
  state = {
    sitePageDefinitions: [], // data
    sitePageObjects: [], // data
    siteMedias: [],
    sitePageDefinition: null, // selected
    sitePageDefinitionId: null,
    sitePageObject: null,
    sitePageObjectId: null,
    siteMedia: null,
    siteMediaId: null,
    addPageObjects: false,
  };

  addPage = () => {
    const sitePageDefinition = {
      _id: "new",
      language: "nl",
      name: "nieuw",
      title: "",
      remark: "",
      sitePageObjects: [],
    };
    const sitePageDefinitions = this.state.sitePageDefinitions;
    sitePageDefinitions.push(sitePageDefinition);
    this.setState({ sitePageDefinitions });
    this.setState({ sitePageDefinitionId: "new" });
    this.setState({ sitePageDefinition });
  };

  addObject = () => {
    const sitePageObject = {
      _id: "new",
      language: "nl",
      name: "nieuw",
      title: "",
      linkUrl: "",
      text: "",
      image: "",
    };
    const sitePageObjects = this.state.sitePageObjects;
    sitePageObjects.push(sitePageObject);
    this.setState({ sitePageObjects });
    this.setState({ sitePageObjectId: "new" });
    this.setState({ sitePageObject });
  };

  addMedia = () => {
    const siteMedia = {
      _id: "new",
      name: "nieuw",
      type: "",
      url: "",
      movieImageUrl: "",
    };
    const siteMedias = this.state.siteMedias;
    siteMedias.push(siteMedia);
    this.setState({ siteMedias });
    this.setState({ siteMediaId: "new" });
    this.setState({ siteMedia });
  };

  onSPDTableClick = (sitePageDefinitionId, sitePageDefinition) => {
    this.setState({ sitePageDefinitionId });
    this.setState({ sitePageDefinition });
  };

  onSPOTableClick = (sitePageObject, sitePageObjectId) => {
    this.setState({ sitePageObjectId });
    this.setState({ sitePageObject });
  };

  onSMTableClick = (siteMedia, row) => {
    this.setState({ siteMediaId: siteMedia._id });
    this.setState({ siteMedia });
  };

  onSMTableDeleteClick = async (siteMedia, row) => {
    const { data: siteMediaDb } = await deleteSiteMedia(siteMedia._id);
    const siteMedias = this.state.siteMedias;
    const index = siteMedias.findIndex((s) => s._id === siteMediaDb._id);
    if (index !== -1) {
      siteMedias.splice(index, 1);
      this.setState({ siteMedias });
    }
  };

  onSPOTableDelete = (row, rowIndex) => {
    alert("delete " + JSON.stringify(row));
  };

  onSPOTableAdd = (row, rowIndex) => {
    //    alert("add " + JSON.stringify(row));
    const sitePageDefinition = this.state.sitePageDefinition;
    const spo = {
      _id: row._id,
      imagePos: "left",
      imageSize: 3,
    };
    sitePageDefinition.sitePageObjects.push(spo);
    this.setState({ sitePageDefinition });
  };

  onSPOTableEdit = (sitePageObjectId, sitePageObject) => {
    this.setState({ key: "editElements" });
    this.setState({ sitePageObjectId });
    this.setState({ sitePageObject });
  };

  onSavePageDefinition = async (values) => {
    const sitePageDefinition = values;
    const { data: sitePageDefinitionDb } = await saveSitePageDefinition(
      sitePageDefinition
    );
    this.setState({ sitePageDefinition: sitePageDefinitionDb });
    this.setState({ sitePageDefinitionId: sitePageDefinitionDb._id });

    const sitePageDefinitions = this.state.sitePageDefinitions;
    const index = sitePageDefinitions.findIndex((s) => s._id === values._id);
    if (index !== -1) {
      sitePageDefinitions[index] = sitePageDefinitionDb;
    } else {
      sitePageDefinitions.push(sitePageDefinitionDb);
    }
    this.setState({ sitePageDefinitions });
  };

  onSavePageObject = async (values) => {
    const sitePageObject = values;
    // console.log("\nSave PageObject\n");
    // console.log(JSON.stringify(sitePageObject));
    const { data: sitePageObjectDb } = await saveSitePageObject(sitePageObject);
    // console.log("\nSave PageObject DB\n");
    // console.log(JSON.stringify(sitePageObjectDb));
    this.setState({ sitePageObject: sitePageObjectDb });

    const sitePageObjects = this.state.sitePageObjects;
    const index = sitePageObjects.findIndex((s) => s._id === values._id);
    if (index !== -1) {
      sitePageObjects[index] = sitePageObjectDb;
    } else {
      sitePageObjects.push(sitePageObjectDb);
    }
    this.setState({ sitePageObjects });
    //    console.log("\nSave PageObject objects\n");
    //    console.log(JSON.stringify(sitePageObjects));
  };

  onSaveMedia = async (values) => {
    const siteMedia = values;
    const { data: siteMediaDb } = await saveSiteMedia(siteMedia);
    const siteMedias = this.state.siteMedias;
    const index = siteMedias.findIndex((s) => s._id === values._id);
    if (index !== -1) {
      siteMedias[index] = siteMediaDb;
    } else {
      siteMedias.push(siteMediaDb);
    }
    this.setState({ siteMedias });
  };

  //  saveSitePageDefinition = (sitePageDefinition)
  handleChangeSitePageObjects = (action, id, value = "") => {
    /*  alert(
      action +
        " " +
        id +
        " object " +
        JSON.stringify(this.state.sitePageDefinition)
    ); */
    var sitePageDefinition = this.state.sitePageDefinition;
    var index = _.findIndex(sitePageDefinition.sitePageObjects, { _id: id });
    var ae;
    if (action === "remove") {
      sitePageDefinition.sitePageObjects.splice(index, 1);
    } else if (action === "imgWider") {
      sitePageDefinition.sitePageObjects[index].imageSize++;
    } else if (action === "imgSmaller") {
      sitePageDefinition.sitePageObjects[index].imageSize--;
    } else if (action === "imgLeft") {
      sitePageDefinition.sitePageObjects[index].imagePos = "left";
    } else if (action === "imgRight") {
      sitePageDefinition.sitePageObjects[index].imagePos = "right";
    } else if (action === "moveUp" && index > 0) {
      ae = sitePageDefinition.sitePageObjects[index - 1];
      sitePageDefinition.sitePageObjects[index - 1] =
        sitePageDefinition.sitePageObjects[index];
      sitePageDefinition.sitePageObjects[index] = ae;
    } else if (
      action === "moveDown" &&
      index < sitePageDefinition.sitePageObjects.length - 1
    ) {
      ae = sitePageDefinition.sitePageObjects[index + 1];
      sitePageDefinition.sitePageObjects[index + 1] =
        sitePageDefinition.sitePageObjects[index];
      sitePageDefinition.sitePageObjects[index] = ae;
    } else if (action === "backgroundColor") {
      sitePageDefinition.sitePageObjects[index].backgroundColor = value;
    }

    this.setState({ sitePageDefinition });
  };

  handleMediaFilesUploaded = async () => {
    const { data: siteMedias } = await getSiteMedias();
    this.setState({ siteMedias });
  };

  async componentDidMount() {
    const { data: sitePageDefinitions } = await getSitePageDefinitions();
    this.setState({ sitePageDefinitions });

    const { data: sitePageObjects } = await getSitePageObjects();
    this.setState({ sitePageObjects });

    const { data: siteMedias } = await getSiteMedias();
    this.setState({ siteMedias });
  }

  render() {
    const sitePageDefinition = this.state.sitePageDefinition;

    return (
      <React.Fragment>
        <SitePagesDefinitionTable
          sitePageDefinitions={this.state.sitePageDefinitions}
          handleSPDTableClick={this.onSPDTableClick}
        />

        <p>
          Kies hierboven een pagina om te wijzigen. Hieronder kun je de titel en
          de inhoud aanpassen. Het opmerkingen veld is bedoeld om voor jezelf
          vast te leggen wat de bedoeling is van deze pagina om consistente
          doorontwikkeling mogelijk te maken. Daaronder kun je de elementen op
          de pagina kiezen en op de juiste volgorde plaatsen. Op het volgende
          tabblad kun je de elementen wijzigen en toevoegen. Het laatste tabblad
          geeft een preview van jouw wijzigingen.
        </p>
        <Button onClick={() => this.addPage()} color="primary">
          Nieuwe pagina
        </Button>
        <h2>{sitePageDefinition && sitePageDefinition.name}</h2>
        <hr />
        <Tabs
          id="controlled-tab"
          activeKey={this.state.key}
          onSelect={(k) => this.setState({ key: k })}
          className="mb-3"
        >
          <Tab eventKey="editPage" title="Wijzig pagina">
            <br />
            {this.state.sitePageDefinition && (
              <React.Fragment>
                <SitePageDefinitionForm
                  sitePageDefinition={sitePageDefinition}
                  onSubmit={this.onSavePageDefinition}
                />
                <p />
                <h3>Wijzig elementen op pagina {sitePageDefinition.name}</h3>
                <p />
                <Row>
                  {sitePageDefinition &&
                    sitePageDefinition.sitePageObjects &&
                    sitePageDefinition.sitePageObjects.map((o) => (
                      <PageListItemEdit
                        sitePageObjectId={o._id}
                        sitePageObject={_.find(this.state.sitePageObjects, {
                          _id: o._id,
                        })}
                        imagePos={o.imagePos}
                        imageSize={o.imageSize}
                        backgroundColor={o.backgroundColor}
                        onChangeSitePageObjects={
                          this.handleChangeSitePageObjects
                        }
                        handleEditClick={this.onSPOTableEdit}
                      />
                    ))}
                </Row>
                <p />
                <h3>Kies elementen voor pagina {sitePageDefinition.name}</h3>
                <p />
                <SitePageObjectsTable
                  sitePageObjects={this.state.sitePageObjects}
                  handleSPOTableClick={this.onSPOTableAdd}
                  filterOnSitePageDefinition={false}
                  buttonText={"Voeg toe"}
                />
              </React.Fragment>
            )}
          </Tab>
          <Tab eventKey="editElements" title="Elementen">
            <div>editPagePage</div>
            <SitePageObjectsTable
              sitePageObjects={this.state.sitePageObjects}
              handleSPOTableClick={this.onSPOTableClick}
              filterOnSitePageDefinition={false}
            />
            <p>
              <Button onClick={() => this.addObject()}>Nieuw</Button>
            </p>
            {this.state.sitePageObject && (
              <SitePageObjectForm
                sitePageObject={this.state.sitePageObject}
                siteMediasList={this.state.siteMedias}
                doSubmit={this.onSavePageObject}
              />
            )}
          </Tab>

          <Tab eventKey="media" title="Media">
            <SiteMediaTable
              siteMedias={this.state.siteMedias}
              handleSMTableClick={this.onSMTableClick}
              handleSMTableDeleteClick={this.onSMTableDeleteClick}
            />
            <Button onClick={() => this.addMedia()} color="primary">
              Nieuw
            </Button>
            <hr />
            <h2>Upload files</h2>
            <p>
              Je kunt meerdere files selecteren en die worden tegelijkertijd
              geupload. Tijdens het uploaden wordt de database bijgewerkt en
              verschijnen de imagees/movies in bovenstaande lijst, waarna deze
              bijgewerkt kan worden.
            </p>
            <FormUpload
              uploadtype={"sitemedia"}
              onFilesUploaded={this.handleMediaFilesUploaded}
            />
            <hr />
            {this.state.siteMedia && (
              <SiteMediaForm
                siteMedia={this.state.siteMedia}
                onSubmit={this.onSaveMedia}
              />
            )}
          </Tab>
          <Tab eventKey="cropImage" title="Crop en schaal">
            <EditImage />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default EditInfoSite;
