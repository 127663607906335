import React, { useState, useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Row, Col, Container, Button } from "react-bootstrap";

import FormTextInput from "../common/formTextInput";
import FormSelect from "../common/formSelect";
import FormSelectList from "../common/formSelectList";
import TextEditor from "../common/textEditor2";
import FormCheckbox from "./../common/formCheckbox";
//import ControlledEditor from "../common/controlledEditor";
import Media from "../common/media";
import SiteSessionDatesTable from "./siteSessionDatesTable";

import { getCustomers } from "../../services/customerService";
import { getProjects } from "../../services/projectService";

import {
  MdDeleteOutline,
  MdArrowUpward,
  MdArrowDownward,
} from "react-icons/md";

/*
const kSitePageObjectForm = () => {
  constructor(props) {
    super(props);
    this.state = {
      sitePageObject: {},
      siteMediasList: [],
      onSubmit: this.props.onSubmit,
      initialValues: {
        name: "",
        title: "",
        remark: "",
        topSiteListObject: {},
      },
      siteMediaCarousel: [],
      text: "",
    };
  }

  populateSitePageObject() {
    //  try {
    console.log("populate Form SitePageObject ", this.props.sitePageObject);
    this.setState({ sitePageObject: this.props.sitePageObject });
    this.setState({ siteMediasList: this.props.siteMedias });
    const initialValues = this.mapToForm(this.props.sitePageObject);
    this.setState({ initialValues });
    this.setState({ text: this.props.sitePageObject.text });

    //  } catch (ex) {
    //    console.log("Error getting ContactPerson on id ", ex);
    //    if (ex.response && ex.response.status === 404)
    //      this.props.history.replace("/not-found");
    //  }
  }

  mapToForm(sitePageObject) {
    let initialValues = sitePageObject;
    // here new default values, not in the db, can be added
    return initialValues;
  }

  mapToDb(sitePageObject) {
    let sitePageObjectdb = sitePageObject;
    return sitePageObjectdb;
  }

  // populateSelectLists() {}

  async componentDidMount() {
    if (this.props.sitePageObject)
      // await this.populateContactPerson();
      this.populateSitePageObject();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.sitePageObject._id !== this.props.sitePageObject._id)
      this.populateSitePageObject();
    // await this.populateContactPerson();
  }

  doSubmit = async (values) => {
    values.text = this.state.text;
    this.state.onSubmit(values);
  };

  setText = (text) => {
    console.log("de text ", text);
    this.setState({ text });
  };
*/
const SitePageObjectForm = ({ sitePageObject, siteMediasList, doSubmit }) => {
  const [showSessionForm, setShowSessionForm] = useState(false);
  const [customerList, setCustomerList] = useState({
    options: [],
  });
  const [projectList, setProjectList] = useState({
    options: [],
  });

  if (!sitePageObject.siteMedias) {
    const siteMedias = [];
    if (sitePageObject.siteMedia) siteMedias.push(sitePageObject.siteMedia);
    sitePageObject.siteMedias = siteMedias;
  }
  if (!sitePageObject.elementType) {
    sitePageObject.elementType = "std";
  }

  const fetchCustomerList = async () => {
    if (customerList.options.length === 0) {
      const { data: customers } = await getCustomers();
      const customerList = {
        options: [{ value: "", name: "kies ..." }],
      };
      customers.map((c) => {
        customerList.options.push({ value: c._id, name: c.name });
      });
      setCustomerList(customerList);
    }
  };

  const fetchProjectList = async () => {
    if (projectList.options.length === 0) {
      const { data: projects } = await getProjects();
      const projectList = {
        options: [{ value: "", name: "kies ..." }],
      };
      projects.map((c) => {
        projectList.options.push({ value: c._id, name: c.name });
      });
      setProjectList(projectList);
    }
  };

  const checkElementTypeStates = (elementType) => {
    switch (elementType) {
      case "std":
        setShowSessionForm(false);
        break;
      case "session":
        setShowSessionForm(true);
        if (!sitePageObject.sessionDates) {
          sitePageObject.sessionDates = [];
          const sessionDate = {
            id: 1,
            date: undefined,
            time: undefined,
            maxParticipants: undefined,
          };
          sitePageObject.sessionDates.push(sessionDate);
        }
        //        fetchCustomerList();
        //        fetchProjectList(); // todo: filter projecten op klant
        break;
    }
  };

  useEffect(() => {
    checkElementTypeStates(sitePageObject.elementType);
  }, [sitePageObject]);
  //

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={sitePageObject}
        validationSchema={Yup.object({
          //name: Yup.string().required("Vereist"),
          //value: Yup.string().required("Vereist"),
          //            typename: Yup.number().min(1).required("Kies"),
        })}
        onSubmit={async (values) => {
          doSubmit(values);
        }}
      >
        {({ handleChange, handleBlur, setFieldValue, values }) => (
          <div className="container">
            <Form>
              <button type="submit">Opslaan</button>
              <div className="row">
                <div className="col-6">
                  {/* <!--left side --> */}
                  <div className="form-group row">
                    <FormSelect label="Taal" name="language">
                      <option value="">Selecteer een taal</option>
                      <option value="nl">Nederlands</option>
                    </FormSelect>
                  </div>
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Naam"
                      name="name"
                      type="text"
                    />
                  </div>
                  <div className="form-group row">
                    <FormTextInput label="Titel" name="title" type="text" />
                  </div>
                  <div className="form-group row">
                    <FormSelect
                      label="Type"
                      name="elementType"
                      id="spofet"
                      onChange={() => {
                        var x = document.getElementById("spofet").value;
                        setFieldValue("elementType", x);
                        checkElementTypeStates(x);
                        console.log("dinges changed ", values.elementType, x);
                      }}
                    >
                      <option value="std">Standaard</option>
                      <option value="session">Sessie (serie)</option>
                    </FormSelect>
                  </div>
                  {/*showSessionForm && (
                    <>
                      <br />
                      Noodzakelijk bij sessie:
                      <br />
                      <FormSelectList
                        label="Klant"
                        list={customerList}
                        name="customerId"
                      />
                      <br />
                      <FormSelectList
                        label="Project"
                        list={projectList}
                        name="projectId"
                      />
                    </>
                  )*/}
                  <div className="form-group row">
                    <FormCheckbox
                      label="Rond / ovaal maken foto"
                      name="roundImage"
                    />
                  </div>
                  <div className="form-group row">
                    <TextEditor
                      setFieldValue={(val) => setFieldValue("text", val)}
                      value={values.text}
                      name="text"
                    />

                    {/* <FormTextArea label="Text" name="text" type="text" />
                    <ControlledEditor
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      value={values.text}
                      name="text"
                      // parentUpdate={this.setText}
                    /> */}
                  </div>
                </div>
                <div className="col-6">
                  <FieldArray name="siteMedias">
                    {({ swap, push, remove }) => (
                      <div>
                        {values.siteMedias.length > 0 &&
                          values.siteMedias.map((media, index) => (
                            <div key={index} className="form-group row">
                              <Media
                                style={{ width: "100%" }}
                                className="img-fluid img-thumbnail"
                                media={media}
                                roundImage={values.roundImage}
                              />
                              <Container>
                                <Row>
                                  <Col>
                                    <MdArrowUpward
                                      onClick={() => {
                                        if (index > 0) {
                                          swap(index - 1, index);
                                        }
                                      }}
                                      fill="blue"
                                      size="30"
                                    />
                                    <MdArrowDownward
                                      onClick={() => {
                                        if (
                                          index <
                                          values.siteMedias.length - 1
                                        ) {
                                          swap(index, index + 1);
                                        }
                                      }}
                                      fill="blue"
                                      size="30"
                                    />
                                    <MdDeleteOutline
                                      onClick={() => remove(index)}
                                      fill="blue"
                                      size="30"
                                    />
                                  </Col>
                                </Row>
                              </Container>
                              <div border="1">
                                <h6>delay, on click and text on image</h6>
                                <FormTextInput
                                  label="Carousel delay in ( miliseconden )"
                                  name={`siteMedias.${index}.delay`}
                                />
                                <FormTextInput
                                  label="Link url ( klik op foto in carousel )"
                                  name={`siteMedias.${index}.url`}
                                  type="text"
                                />
                                Text op foto in carousel
                                <TextEditor
                                  setFieldValue={(val) =>
                                    setFieldValue(
                                      `siteMedias.${index}.text`,
                                      val
                                    )
                                  }
                                  value={values.siteMedias[index].text}
                                  name={`siteMedias.${index}.text`}
                                />
                                {/*}
                              <ControlledEditor
                                onChange={setFieldValue}
                                onBlur={handleBlur}
                                value={media.text}
                                name={`media[${index}].text`}
                                // parentUpdate={this.setText}
                              /> */}
                              </div>
                              <hr
                                style={{
                                  background: "darkgrey",
                                  color: "darkgrey",
                                  borderColor: "darkgrey",
                                  height: "3px",
                                }}
                              />
                            </div>
                          ))}

                        <div className="container">
                          <div className="row text-center text-lg-start">
                            {siteMediasList.length &&
                              siteMediasList.map((media) => (
                                <div className="col-lg-3 col-md-4 col-6">
                                  <div
                                    className="d-block mb-4 h-100"
                                    onClick={(e) => {
                                      var siteMedia = {
                                        Id: media._id,
                                        type: media.type,
                                        path: media.path,
                                        url: media.url,
                                        movieImageUrl: media.movieImageUrl,
                                        delay: "",
                                        text: "",
                                      };
                                      push(siteMedia);
                                      //this.setState({ sitePageObject });
                                    }}
                                  >
                                    <Media
                                      className="img-fluid img-thumbnail"
                                      media={media}
                                      roundImage={false}
                                      // src={`${process.env.REACT_APP_SRV_URL}/${m.path}`}
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                  {/* this.state.sitePageObject &&
                    this.state.sitePageObject.siteMedias.length &&
                    this.state.sitePageObject.siteMedias.map((m) => (
                      <Container>
                        <Image
                          style={{ width: "100%" }}
                          src={`${process.env.REACT_APP_SRV_URL}/${m.path}`}
                        />
                        <h6>text on image, url on click</h6>
                      </Container>
                    )) */}
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default SitePageObjectForm;
