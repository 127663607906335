import http from "./httpService";

const apiEndpoint = "/users";

export function register(user) {
  const postuser = {
    email: user.email,
    password: user.password,
    name: user.name,
    phonenr: user.phonenr,
    additionalInfo: {},
    userType: { isUser: true },
  };
  for (const property in user) {
    if (!postuser[property]) {
      if (property.substring(0, 15) === "additionalInfo_") {
        postuser.additionalInfo[property.substring(15)] = user[property];
      } else if (property.substring(0, 9) === "userType_") {
        postuser.userType[property.substring(9)] = user[property];
      }
    }
  }

  return http.post(apiEndpoint, postuser);
  /*
  {
    email: user.email,
    password: user.password,
    name: user.name,
    phonenr: user.phonenr,
    additionalInfo: user.addtionalInfo,
    userType: user.userType,
  });
  */
}
