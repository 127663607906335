import React from "react";
import { useState, useEffect } from "react";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import { Row, Col } from "react-bootstrap";

import CustomerTable from "./customerTable";
import CustomerForm from "./customerForm";

import { getCustomers } from "../../../services/customerService";

const Customer = ({
  customer,
  customers,
  setCustomerId,
  setCustomer,
  setCustomers,
}) => {
  const [activeCustomerTab, setActiveCustomerTab] = useState("editNotes");
  const [loadingCustomers, setLoadingCustomers] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingCustomers(true);
      const { data: customersDb } = await getCustomers("name");
      setCustomers(customersDb);
      setLoadingCustomers(false);
    };
    fetchData();
  }, []);

  if (loadingCustomers) {
    return <div />;
  }

  return (
    <>
      <Row>
        <CustomerTable customers={customers} setCustomerId={setCustomerId} />
        <Col className="sm-7">
          <CustomerForm customer={customer} setCustomer={setCustomer} />
        </Col>
        <Col className="sm-5">
          <Tabs
            id="customerTab"
            activeKey={activeCustomerTab}
            onSelect={(k) => setActiveCustomerTab(k)}
          >
            <Tab eventKey="editNotes" title="Notities"></Tab>
            <Tab eventKey="editContacts" title="Contacten"></Tab>
            <Tab eventKey="editAdresses" title="Adressen"></Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default Customer;
