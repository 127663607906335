import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Row, Col, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  dateTimeToString,
  mySQLStringToDate,
  longDateTimeToString,
} from "./common/annaformats";
import { saveSitePageObject } from "./../services/sitePageObjectService";
import { saveContact } from "./../services/contactService";

import FormTextInput from "./common/formTextInput";
import RegisterForSessionTable from "./registerForSessionTable";
import { useState } from "react";
import { useEffect } from "react";
// import { getFacultativeSession } from "./../fakeServices/fakeSessionPartService";

const RegisterForSessionForm = ({ sessionSeries }) => {
  const [modalShow, setModalShow] = useState(false);
  const [option, setOption] = useState(0);
  const [modalWasShown, setModalWasShown] = useState(false);
  const navigate = useNavigate();
  const labelclass = `("col-lg-4 col-md-4 col-sm-4 col-xs-12")`;
  const inputclass = `("col-lg-8 col-md-8 col-sm-8 ")`;

  const submitRegisterForSessionForm = async (values) => {
    const contact = {
      ...values,
      type: "registerforsession",
      id: sessionSeries[values.option]._id,
      sessionname: sessionSeries[values.option].name,
    };
    //    contact.subject =
    //      "Inschrijven sessie: " + submitRegisterForSessionForm.name;
    // console.log(JSON.stringify(contact));
    const { data: contactDb } = await saveContact(contact);

    setModalShow(true);
    setModalWasShown(true);
  };

  useEffect(() => {
    if (!modalShow && modalWasShown) {
      navigate("/home");
    }
  }, [modalShow, modalWasShown]);

  return (
    <>
      <ModalBedankt
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
      <Formik
        enableReinitialize
        initialValues={{
          name: "",
          email: "",
          phonenr: "",
          method: "",
          phoneable: "",
          date: "",
          option: 0,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Vereist"),
          email: Yup.string()
            .email("Geen valide email adres")
            .required("Vereist"),
          //value: Yup.string().required("Vereist"),
          //            typename: Yup.number().min(1).required("Kies"),
        })}
        onSubmit={async (values) => {
          submitRegisterForSessionForm(values);
        }}
      >
        {({ setFieldValue, values }) => (
          <div className="container">
            <Form>
              <div className="row">
                <div className="col-7">
                  <h3>Persoonsgegevens</h3>
                  <div className="row">
                    <div className={labelclass}>Naam *</div>
                    <div className={inputclass}>
                      <div className="form-group row">
                        <FormTextInput label="" name="name" type="text" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className={labelclass}>Email *</div>
                    <div className={inputclass}>
                      <div className="form-group row">
                        <FormTextInput label="" name="email" type="text" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className={labelclass}>Telefoonnummer *</div>
                    <div className={inputclass}>
                      <div className="form-group row">
                        <FormTextInput label="" name="phonenr" type="text" />
                      </div>
                    </div>
                  </div>
                  <br />

                  <br />
                  <div className="row">
                    <div className={labelclass}></div>
                    <div className={inputclass}>
                      <div className="form-group row">
                        <button type="submit">Verstuur</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5">
                  {sessionSeries && sessionSeries.length > 1 && (
                    <>
                      <div id="my-radio-group">Kies start datum</div>
                      <div role="group" aria-labelledby="my-radio-group">
                        {sessionSeries &&
                          sessionSeries.length > 1 &&
                          sessionSeries.map((ss, index) => (
                            <>
                              <Field
                                type="radio"
                                id={`option_${index}`}
                                name="option"
                                value={index}
                              />
                              <label for={`option_${index}`}>
                                {longDateTimeToString(
                                  mySQLStringToDate(ss.sessions[0].datetime)
                                )}
                              </label>
                              <br />
                            </>
                          ))}
                      </div>
                    </>
                  )}
                  {sessionSeries && sessionSeries.length && (
                    <RegisterForSessionTable
                      sessions={sessionSeries[values.option].sessions}
                    />
                  )}
                </div>
                <br />
                <br />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

function ModalBedankt(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/*}
      <Modal.Header closeButton />
              <Modal.Title id="contained-modal-title-vcenter">Bedankt</Modal.Title>
  </Modal.Header> */}
      <Modal.Body>
        <h4>Bedankt voor de aanmelding</h4>
        <p>
          U ontvangt zo spoedig mogelijk een mail met een link voor de
          bevestiging van de inschrijving.
        </p>
        <Button onClick={props.onHide}>Sluiten</Button>
      </Modal.Body>
      {/*      <Modal.Footer>

</Modal.Footer> */}
    </Modal>
  );
}

export default RegisterForSessionForm;

/*

  const dateList = { options: [] };
  if (sitePageObject) {
    dateList.options.push({ value: "", name: "kies.." });
    for (var i = 0; i < sitePageObject.sessionDates.length; i++) {
      var dts =
        //        dateTimeToString(
        //          mySQLStringToDate(sitePageObject.sessionDates[i].datetime)
        //        ) +
        //        " of " +
        longDateTimeToString(
          mySQLStringToDate(sitePageObject.sessionDates[i].datetime)
        );
      dateList.options.push({ value: i, name: dts });
    }
  }



function getDates(sessions, option) {
  const dateList = { options: [] };

  const datesForSession = sessions
    .filter((s) => s._id === option.value)
    .dates.map((d, index) => {
      const option = { value: d._id, name: d.date };
      dateList.options.push(option);
    });
  return dateList;
}


*/
