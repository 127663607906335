import React from "react";
/*
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
*/
import DatePicker from "react-date-picker";
import FormTextInput from "../common/formTextInput";
import { Formik, Form } from "formik";

import Button from "react-bootstrap/Button";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import {
  MdDeleteOutline,
  MdArrowUpward,
  MdArrowDownward,
} from "react-icons/md";
/*
class TableDatePicker extends React.Component {
  //  static propTypes = {
  //    value: PropTypes.number,
  //    onUpdate: PropTypes.func.isRequired,
  //  };
  
  static defaultProps = {
    value: 0,
  };
  getValue() {
    return parseInt(this.range.value, 10);
  }
  render() {
    const { value, onUpdate, ...rest } = this.props;
    return [
      <input
        {...rest}
        key="range"
        ref={(node) => (this.range = node)}
        type="range"
        min="0"
        max="100"
      />,
      <button
        key="submit"
        className="btn btn-default"
        onClick={() => onUpdate(this.getValue())}
      >
        done
      </button>,
    ];
  }
}
*/

class SiteSessionDatesTable extends React.Component {
  /*
  state = {
    sessionDates: this.props.sessionDates,
    onButtonClick: this.props.handleButtonClick,
    buttonText: this.props.buttonText,
    rowIndex: -1,
  };

  setRowIndex = (rowIndex) => {
    this.setState({ rowIndex });
  };
  updateDate = (value) => {
    this.state.sessionDates[this.state.rowIndex].date = value;
  };

  doSubmit = (values) => {
    console.log("values");
  };
  afterSaveCell(oldValue, newValue) {
    console.log("--after save cell--");
    console.log("New Value was apply as");
    console.log(newValue);
    console.log(`and the type is ${typeof newValue}`);
  }

  render() {
    const columns = [
      {
        dataField: "date",
        text: "Datum",
        validator: (newValue, row, column) => {
          /*          if (isNaN(newValue)) {
            return {
              valid: false,
              message: "Numeriek veld",
            };
          } * /
          return true;
        },
        /*        formatter: (cell, row, rowIndex, formatExtraData) => {
          let dateObj = cell;
          if (typeof cell !== "object") {
            dateObj = new Date(cell);
          }
          return `${("0" + dateObj.getUTCDate()).slice(-2)}/${(
            "0" +
            (dateObj.getUTCMonth() + 1)
          ).slice(-2)}/${dateObj.getUTCFullYear()}`;
        },
* / editor: {
          type: Type.DATE,
        },
      },
      {
        dataField: "time",
        text: "Tijd",
      },
      {
        dataField: "maxParticipants",
        text: "Aantal deelnemers",
        validator: (newValue, row, column) => {
          if (isNaN(newValue)) {
            return {
              valid: false,
              message: "Numeriek veld",
            };
          }
          return true;
        },
      },
      {
        formatter: (cell, row, rowIndex) => {
          <MdDeleteOutline
            //            onClick={() => remove(rowIndex)}
            fill="blue"
            size="30"
          />;
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        //      onDoubleClick: (e, row, rowIndex) => {
        //        if (this.state.handleSPOTableClick !== undefined) {
        //          this.state.handleSPOTableClick(row._id, row);
        //        } else {
        //          this.setState({ redirect: `/organization/${row._id}` });
        //        }
      },
    };
*/
  render() {
    return <div />;
    /*
      <>
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.sessionDates}
          columns={columns}
          striped
          hover
          condensed
          //          noDataIndication={indication}
          pagination={paginationFactory()}
          cellEdit={cellEditFactory({
            mode: "click",
            blurToSave: true,
            afterSaveCell: this.afterSaveCell,
          })}
          rowEvents={rowEvents}
        ></BootstrapTable>
      </>
    ); */
  }
}

export default SiteSessionDatesTable;
