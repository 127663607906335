import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import parse from "html-react-parser";

import Media from "./media";

class SimpleCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteMediaCarousel: [],
      //      height: null
    };
  }
  // (siteMediaCarousel) {

  //  handleSelect = (selectedIndex, e) => {
  //    setIndex(selectedIndex);
  //  };
  //     <Carousel activeIndex={index} onSelect={handleSelect}>

  setCarouselHeight = (height, index) => {
    if (index === 0 && (!this.state.height || this.state.height < 100)) {
      this.setState({ height });
    }
  };

  render() {
    return (
      <Carousel
        fade
        indicators="false"
        //        style={{ height: `${this.state.height}px` }}
      >
        {this.props.siteMediaCarousel.length &&
          this.props.siteMediaCarousel.map((media, index) => (
            <Carousel.Item interval={media.delay}>
              {/*<Carousel.Caption>{parse(media.text)}</Carousel.Caption>*/}
              <Media
                key={index + media.id}
                className="d-block w-100"
                stylekk={{ width: "100%" }}
                media={media}
                roundImage={this.props.roundImage}
                mediaText={parse(media.text)}
                setCellHeight={this.setCarouselHeight}
                heightCarousel={this.state.height}
                index={index}
              />
            </Carousel.Item>
          ))}
      </Carousel>
    );
  }
}

export default SimpleCarousel;
