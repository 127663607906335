import React, { useState } from "react";
import { useField } from "formik";

const FormDateTimeField = ({ label, date, time, ...props }) => {
  const [field, meta, { setValue }] = useField(props);
  const [datetime, setDatetime] = useState(field.value);

  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <br />
      <span>
        <input
          type="date"
          {...field}
          {...props}
          value={date}
          placeholder="dd-mm-yyyy"
          //          min="1997-01-01"
          //          max="2030-12-31"
          //        selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            const dt = datetime;
            dt.date = val.currentTarget.valueAsDate.toISOString();
            setDatetime(dt);
            setValue(dt);
          }}
        />
        <input
          type="time"
          {...field}
          {...props}
          value={time}
          //        selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            const dt = datetime;
            const ms = val.currentTarget.valueAsNumber;
            const h = Math.floor(ms / 3600000);
            var rest = ms - h * 3600000;
            const m = Math.floor(rest / 60000);
            dt.time = ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2);
            setDatetime(dt);
            setValue(dt);
          }}
        />
      </span>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default FormDateTimeField;

/*

import DateTimePicker from "react-datetime-picker";

const FormDateTimeField = ({ label, ...props }) => {
  const [field, meta, { setValue }] = useField(props);

  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <br />
      <DateTimePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setValue(val);
        }}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default FormDateTimeField;
*/
