import React from "react";
import { useField } from "formik";
import { MdSearch } from "react-icons/md";

const FormTextInputSearch = ({ label, onClick, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  if (!label || label === "") {
    return (
      <>
        <input className="text-input" {...field} {...props} />
        <MdSearch onClick={onClick} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}

        <br />
      </>
    );
  }
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <br />
      <input className="text-input" {...field} {...props} />{" "}
      <MdSearch onClick={onClick} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
      <br />
    </>
  );
};

export default FormTextInputSearch;

/*

https://formik.org/docs/tutorial

     <FormTextInput label="Soort" name="type.name" type="text" />

*/
