import React from "react";
/*
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
*/
import { MdDeleteOutline } from "react-icons/md";

const SessionTable = ({ sessions, setSessionId }) => {
  /*  const deleteSession = (id) => {
    alert("zeker weten?");
  };

  const sessionColumns = [
    {
      dataField: "name",
      text: "Naam",
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: "40%" };
      },
    },
    {
      dataField: "datetime",
      text: "Datum tijd",
      headerStyle: (column, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "contact",
      text: "Sessie begeleider",
      headerStyle: (column, colIndex) => {
        return { width: "35%" };
      },
    },
    {
      formatter: (cell, row, rowIndex) => {
        return <MdDeleteOutline onClick={() => deleteSession(row._id)} />;
      },
    },
  ];
  const sessionRowEvents = {
    onClick: (e, row, rowIndex) => {
      setSessionId(row._id);
    },
  };

  const sessionPagination = paginationFactory({
    sizePerPageList: [
      {
        text: "1",
        value: 1,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "Alle",
        value: sessions.length,
      },
    ],
  });*/
  return <div />;
  /*
  return (
    <BootstrapTable
      bootstrap4
      keyField="_id"
      data={sessions}
      columns={sessionColumns}
      striped
      hover
      condensed
      pagination={sessionPagination}
      filter={filterFactory()}
      rowEvents={sessionRowEvents}
    ></BootstrapTable>
  );
*/
};

export default SessionTable;
