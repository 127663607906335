import React, { useState, useEffect, useMemo } from "react";
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table"; // https://tanstack.com/table/v8  https://tanstack.com/table/v8/docs/examples/react/row-selection

import Media from "../common/media";
import Button from "react-bootstrap/Button";
import parse from "html-react-parser";

function indication() {
  "Huh niets te doen????";
}

/*  Parameters
                  sitePageDefinition={this.state.sitePageDefinition}
                  filterOnSitePageDefinition={true}
                  buttonText={"Verwijder"}
                  handleButtonClick={this.onSPOTableDelete}

*/

const SitePageObjectsTable = ({
  sitePageObjects,
  handleSPOTableClick,
  filterOnSitePageDefinition,
}) => {
  const [data, setData] = useState([]);
  const [columnResizeMode, setColumnResizeMode] = useState("onChange"); // onEnd is alternatief
  const [columnFilters, setColumnFilters] = useState([]);

  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");

  useEffect(() => {
    setData(sitePageObjects);
  }, [sitePageObjects]);

  const onSelectRow = (_id) => {
    handleSPOTableClick(sitePageObjects.filter((s) => s._id == _id)[0], _id);
  };

  const columns = useMemo(
    () => [
      {
        header: "Name",
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: "language",
            cell: (info) => info.getValue(),
            header: () => <span>Taal</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorFn: (row) => row.name,
            id: "name",
            cell: (info) => info.getValue(),
            header: () => <span>Naam</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
      {
        header: "Info",

        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: "title",
            id: "title",
            header: () => <span>Titel</span>,
            footer: (props) => props.column.id,
          },
          {
            header: "Image",
            accessorFn: (row) => row.name,
            id: "image",
            footer: (info) => info.column.id,
            cell: (info) => {
              //info.getValue()
              if (!info.row.original.siteMedias) {
                return <div />;
              }
              const media = info.row.original.siteMedias[0];
              // console.log("media ", media);
              if (media && !media.type) {
                media.type = "image";
                // console.log("media modified", media);
              } else if (!media) {
                return <div />;
              }
              return (
                <React.Fragment>
                  {
                    //media &&
                    <div>
                      <Media
                        media={media}
                        src={`${process.env.REACT_APP_SRV_URL}/${media.path}`}
                        roundImage={info.row.original.roundImage}
                        className="tableImage"
                      />
                      {
                        //row.roundImage
                      }
                    </div>
                  }
                </React.Fragment>
              );
            },
            minSize: 120,
            size: "20%",
          },
          {
            accessorKey: "text",
            id: "text",
            cell: (info) => parse(info.getValue()),
            header: () => <span>Opmerking</span>,
            footer: (props) => props.column.id,
            minSize: 120,
            size: "55%",
          },
        ],
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    state: {
      rowSelection,
    },
    enableRowSelection: true, //enable row selection for all rows
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === "fullName") {
      if (table.getState().sorting[0]?.id !== "fullName") {
        table.setSorting([{ id: "fullName", desc: false }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);

  return (
    <div className="p-2 sidebarTable">
      <table className="sidebarTable">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => {
                onSelectRow(row.original._id);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td className="sidebarTable" key={cell.id}>
                  {cell.getValue() && // RBo  wanneer value is null of key ontbreekt in object geeft crash
                    flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </button>
        <span className="flex items-center gap-1">
          Page
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded" // w-16"
            style={{ width: 60 }}
            min="1"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <br />
      <div>
        {Object.keys(rowSelection).length} of{" "}
        {table.getFilteredRowModel().rows.length} Total Rows Selected
      </div>
    </div>
  );
};

function Filter({ column, table }) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={column.getFilterValue()?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [e.target.value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={column.getFilterValue()?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [old?.[0], e.target.value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={column.getFilterValue() ?? ""}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Zoek...`}
      className="w-36 border shadow rounded"
    />
  );
}

/*
      {
        dataField: "language",
        text: "Taal",
        filter: textFilter(),
      },
      {
        dataField: "name",
        text: "Naam",
        filter: textFilter(),
      },
      {
        dataField: "elementType",
        text: "Type",
        filter: textFilter(),
      },
      {
        dataField: "image",
        text: "Image",
        formatter: (cell, row) => {
          if (!row.siteMedias) {
            return <div />;
          }
          const media = row.siteMedias[0];
          // console.log("media ", media);
          if (media && !media.type) {
            media.type = "image";
            // console.log("media modified", media);
          } else if (!media) {
            return <div />;
          }
          return (
            <React.Fragment>
              {media && (
                <div>
                  <Media
                    media={media}
                    //                 src={`${process.env.REACT_APP_SRV_URL}/${row.siteMedia.path}`}
                    roundImage={row.roundImage}
                    className="tableImage"
                  />
                  {row.roundImage}
                </div>
              )}
            </React.Fragment>
          );
        },
      },
      {
        dataField: "title",
        text: "Title",
      },
      {
        dataField: "text",
        text: "Text",
        formatter: (cell, row, rowIndex) => {
          return <div>{parse(row.text)}</div>;
        },
      },
      {
*/

/*

class SitePageObjectsTable_ extends React.Component {
  state = {
    sitePageDefinitionId: null,
    sitePageObjects: this.props.sitePageObjects,
    handleSPOTableClick: this.props.handleSPOTableClick,
    onButtonClick: this.props.handleButtonClick,
    filterOnSitePageDefinition: this.props.filterOnSitePageDefinition,
    buttonText: this.props.buttonText,
  };

  async componentDidMount(prevProps) {
    var sitePageObjects = [];
    if (this.state.filterOnSitePageDefinition) {
      if (
        this.props.sitePageDefinition &&
        (!prevProps ||
          !prevProps.sitePageDefinition ||
          this.props.sitePageDefinition._id != prevProps.sitePageDefinition._id)
      ) {
        this.props.sitePageDefinition.listObjects.map((o) => {
          //          const { data: sitePageObject } = await getSiteListObject(o._id);
          //          sitePageObjects.push(sitePageObject);
        });
        this.setState({ sitePageObjects });
      }
    } else {
      //      const { data: sitePageObjects } = await getSiteListObjects();
      this.setState({ sitePageObjects });
    }
  }

  async componentDidUpdate(prevProps) {
    const sitePageObjects = [];
    if (
      this.props.sitePageDefinition &&
      (!prevProps ||
        !prevProps.sitePageDefinition ||
        this.props.sitePageDefinition._id != prevProps.sitePageDefinition._id)
    ) {
      this.props.sitePageDefinition.listObjects.map((o) => {
        //        sitePageObjects.push(await getSiteListObject(o._id))
        //const { data: sitePageObject } = await getSiteListObject(o._id);
        //sitePageObjects.push(sitePageObject);
      });
      this.setState({ sitePageObjects });
    }
  }

  deleteRow(rowIndex) {
    const sitePageObjects = this.state.sitePageObjects;
    sitePageObjects.splice(rowIndex, 1);
    this.setState({ sitePageObjects });
    return true;
  }
* /
  render() {
    / *
    const columns = [
      {
        dataField: "language",
        text: "Taal",
        filter: textFilter(),
      },
      {
        dataField: "name",
        text: "Naam",
        filter: textFilter(),
      },
      {
        dataField: "elementType",
        text: "Type",
        filter: textFilter(),
      },
      {
        dataField: "image",
        text: "Image",
        formatter: (cell, row) => {
          if (!row.siteMedias) {
            return <div />;
          }
          const media = row.siteMedias[0];
          // console.log("media ", media);
          if (media && !media.type) {
            media.type = "image";
            // console.log("media modified", media);
          } else if (!media) {
            return <div />;
          }
          return (
            <React.Fragment>
              {media && (
                <div>
                  <Media
                    media={media}
                    //                 src={`${process.env.REACT_APP_SRV_URL}/${row.siteMedia.path}`}
                    roundImage={row.roundImage}
                    className="tableImage"
                  />
                  {row.roundImage}
                </div>
              )}
            </React.Fragment>
          );
        },
      },
      {
        dataField: "title",
        text: "Title",
      },
      {
        dataField: "text",
        text: "Text",
        formatter: (cell, row, rowIndex) => {
          return <div>{parse(row.text)}</div>;
        },
      },
      {
        formatter: (cell, row, rowIndex) => {
          if (this.props.buttonText) {
            return (
              <Button
                onClick={() => this.props.handleButtonClick(row, rowIndex)}
              >
                {this.state.buttonText}
              </Button>
            );
          }
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        //      onDoubleClick: (e, row, rowIndex) => {
        if (this.state.handleSPOTableClick !== undefined) {
          this.state.handleSPOTableClick(row._id, row);
          //        } else {
          //          this.setState({ redirect: `/organization/${row._id}` });
        }
      },
    };

    return (
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={this.props.sitePageObjects}
        columns={columns}
        striped
        hover
        condensed
        noDataIndication={indication}
        pagination={paginationFactory()}
        filter={filterFactory()}
        rowEvents={rowEvents}
      ></BootstrapTable>
    );  
    return <div />;
  }
}
*/
export default SitePageObjectsTable;
