import React from "react";
import { useState, useEffect } from "react";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import { Row, Col } from "react-bootstrap";

import SessionSerieTable from "./sessionSerieTable";
import SessionSerieForm from "./sessionSerieForm";
import SessionSiteTable from "../sessions/sessionSiteTable";

import { getSessionSeries } from "../../../services/sessionSerieService";

const SessionSerie = ({
  projectId,
  projects,
  sessionSerie,
  sessionSeries,
  setSessionSerieId,
  setSessionSerie,
  setSessionSeries,
  surveyList,
  setSurveyList,
}) => {
  const [activeSessionSerieTab, setActiveSessionSerieTab] =
    useState("editNotes");
  const [loadingSessionSeries, setLoadingSessionseries] = useState(true);
  const [filteredSessionSeries, setFilteredSessionSeries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingSessionseries(true);
      const { data: sessionSeriesDb } = await getSessionSeries(
        "name projectId"
      );
      setSessionSeries(sessionSeriesDb);
      filterSessionSeries();
      setLoadingSessionseries(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterSessionSeries();
  }, [projectId]);

  const filterSessionSeries = () => {
    if (!projectId) {
      setFilteredSessionSeries({ ...sessionSeries });
    } else if (projectId) {
      setFilteredSessionSeries(
        sessionSeries.filter((ss) => ss.projectId === projectId)
      );
    }
  };

  const setSitePageObject = (sitePageObjectId, name) => {
    setSessionSerie({
      ...sessionSerie,
      sitePageObjectId: sitePageObjectId,
      sitePageObjectName: name,
    });
  };

  if (loadingSessionSeries) {
    return <div />;
  }

  return (
    <>
      <Row>
        <SessionSerieTable
          sessionSeries={filteredSessionSeries}
          setSessionSerieId={setSessionSerieId}
        />
        <Col className="sm-7">
          <SessionSerieForm
            sessionSerie={sessionSerie}
            setSessionSerie={setSessionSerie}
            projectId={projectId}
            projects={projects}
            surveyList={surveyList}
            setSurveyList={setSurveyList}
          />
        </Col>
        <Col className="sm-5">
          <Tabs
            id="sessionSerieTab"
            activeKey={activeSessionSerieTab}
            onSelect={(k) => setActiveSessionSerieTab(k)}
          >
            <Tab eventKey="editNotes" title="Notities"></Tab>
            <Tab eventKey="editContacts" title="Deelnemers"></Tab>
            <Tab eventKey="editSite" title="Site">
              <SessionSiteTable setSitePageObject={setSitePageObject} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default SessionSerie;
