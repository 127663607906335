import React, { useRef, useEffect } from "react";
import { JsonEditor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

const JsonEditor2 = ({ value = {}, onChange }) => {
  const jsonEditorRef = useRef(null);

  useEffect(() => {
    if (jsonEditorRef.current !== null) {
      jsonEditorRef.current.set(value);
    }
  }, [value]);

  const setRef = (instance) => {
    if (instance) {
      jsonEditorRef.current = instance.jsonEditor;
    } else {
      jsonEditorRef.current = null;
    }
  };

  return <JsonEditor ref={setRef} value={value} onChange={onChange} />;
};

export default JsonEditor2;
