export const dateTimeToString = (dt) => {
  // return // dt.toLocaleString();
  return `${dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate()}-${
    dt.getMonth() < 9 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1
  }-${dt.getFullYear()} ${
    dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours()
  }:${dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes()}`;
};

export const mySQLStringToDate = (dts) => {
  //  "2023-04-27T22:00:00.000Z"
  const date = new Date(Date.parse(dts));
  return date;
};

const dagen = [
  "Zondag",
  "Maandag",
  "Dinsdag",
  "Woensdag",
  "Donderdag",
  "Vrijdag",
  "Zaterdag",
];
const maanden = [
  "Januari",
  "Februari",
  "Maart",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Augustus",
  "September",
  "Oktober",
  "November",
  "December",
];

export const longDateTimeToString = (dt) => {
  const wkday = dt.getDay();
  return `${dagen[wkday]} ${
    dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate()
  } ${maanden[dt.getMonth()]} ${dt.getFullYear()} om ${
    dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours()
  }:${dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes()}`;
};
