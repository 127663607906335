import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./components/home";
import Contact from "./components/contact";
import RegisterForSession from "./components/registerForSession";
import NavBar2 from "./components/navBar2";
import EditInfoSite from "./components/editInfoSite/editInfoSite";
import EditSessions from "./components/editSessions/editSessions";
import RegisterForm from "./components/registerForm";
import LoginForm from "./components/loginForm";
import Logout from "./components/logout";
// import ProtectedRoute from "./components/common/protectedRoute";
import auth from "./services/authService";

import "bootstrap/dist/js/bootstrap.bundle";

import "./App.css";
import "./components/page/page.css";
// import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg";
// import ScrollToTop from "./components/common/scrollToTop";

import { getSitePageDefinitionSpecials } from "./services/sitePageDefinitionService";
import CustomerProject from "./components/backoffice/customerProject";
import Surveys from "./components/backoffice/surveys/surveys";
import MyPersonalPage from "./components/myPersonalPage";
import SurveyInput from "./components/surveyInput";
import InterviewInput from "./components/interviewInput";

const App = () => {
  //  render() {
  //    const { user, who, projects } = this.state;

  const [user, setUser] = useState(null);
  const [projects, setProjects] = useState([]);

  const [survey, setSurvey] = useState(null); // TODO: hoort hier niet
  const newSurvey = {
    name: "",
    version: "",
    description: "",
    surveyJson: "",
  };

  //  const [searchParams] = useSearchParams();
  //  console.log(searchParams);

  useEffect(() => {
    const userg = auth.getCurrentUser();
    setUser(userg);

    const fetchData = async () => {
      /*
      const projects = [];
      const { data: sitePageDefinitions } = await getSitePageDefinitions();
      //      const { data: projectsDb} = await getProjects(); todo getProjects

      for (let i = 0; i < sitePageDefinitions.length; i++) {
        if (sitePageDefinitions[i].type === "project") {
          const menuname =
            sitePageDefinitions[i].projectname &&
            sitePageDefinitions[i].projectname !== ""
              ? sitePageDefinitions[i].projectname
              : "p";
          const project = {
            menuname: menuname,
            href: sitePageDefinitions[i].name,
            nr: parseInt(sitePageDefinitions[i].projectnr),
          };
          projects.push(project);
        }
      }
      projects.sort((firstItem, secondItem) => firstItem.nr - secondItem.nr);
      setProjects(projects);
      setSitePageDefinitions(sitePageDefinitions);
      */
      const { data: projects } = await getSitePageDefinitionSpecials(
        "projects"
      );
      setProjects(projects);
    };

    if (!projects.length) {
      fetchData();
    }
  }, []);

  return (
    <React.Fragment>
      {/* <h1>{document.referrer}</h1> */}
      <NavBar2 user={user} projects={projects}></NavBar2>
      <main className="container">
        {/* <ScrollToTop /> */}
        <Routes>
          <Route index element={<Home page="home" user={user} />} />
          <Route path="home" element={<Home page="home" user={user} />} />
          <Route path="anna" element={<Home page="anna" user={user} />} />
          <Route path="wie" element={<Home page="wie" user={user} />} />
          <Route path="contact" element={<Contact user={user} />} />
          <Route
            path="Ontmoetingen Online"
            element={<Home page="Ontmoetingen Online" user={user} />}
          />
          <Route
            path="Sisterhood"
            element={<Home page="Sisterhood" user={user} />}
          />
          <Route
            path="Zielsverduistering"
            element={<Home page="Zielsverduistering" user={user} />}
          />
          <Route path="Oase" element={<Home page="Oase" user={user} />} />
          <Route
            path="internationaleontmoetingen"
            element={<Home page="internationaleontmoetingen" user={user} />}
          />
          <Route
            path="Muzikale Ontmoetingen"
            element={<Home page="Muzikale Ontmoetingen" user={user} />}
          />
          <Route
            path="huidhonger"
            element={<Home page="huidhonger" user={user} />}
          />
          <Route
            path="overigeprojecten"
            element={<Home page="overigeprojecten" user={user} />}
          />
          <Route path="nieuws" element={<Home page="nieuws" user={user} />} />
          <Route
            path="geschiedenis"
            element={<Home page="geschiedenis" user={user} />}
          />
          <Route path="sessie" element={<Home page="sessie" user={user} />} />
          <Route
            path="vrijwiliger"
            element={<Home page="vrijwiliger" user={user} />}
          />
          <Route
            path="ondersteuning"
            element={<Home page="ondersteuning" user={user} />}
          />
          <Route
            path="donaties"
            element={<Home page="donaties" user={user} />}
          />
          <Route path="overig" element={<Home page="overig" user={user} />} />
          <Route
            path="inschrijvenvoorsessie"
            element={<RegisterForSession user={user} />}
          />
          <Route path="overig" element={<Home page="overig" user={user} />} />
          <Route path="register" element={<RegisterForm />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="logout" element={<Logout />} />
          <Route path="editinfosite" element={<EditInfoSite />} />
          <Route path="editsessions" element={<EditSessions />} />
          <Route path="editcustomers" element={<CustomerProject />} />
          <Route path="survey" element={<Surveys />} />
          <Route path="mijnpagina" element={<MyPersonalPage />} />
          <Route
            path="enqueteresultinput"
            element={<InterviewInput user={user} />}
          />
          <Route path="enqueteresultedit" element={<Home user={user} />} />
          <Route
            path="enqueteedit"
            element={
              <SurveyInput
                user={user}
                survey={newSurvey}
                setSurvey={setSurvey}
              />
            }
          />
        </Routes>
      </main>
    </React.Fragment>
  );
};

export default App;

/*
class App extends Component {
  state = {
    projects: null,
    who: null,
  };
  //props = useParams();

  async componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
    const projects = [];
    const { data: sitePageDefinitions } = await getSitePageDefinitions();
    for (let i = 0; i < sitePageDefinitions.length; i++) {
      if (sitePageDefinitions[i].type === "us") {
        const menuname =
          sitePageDefinitions[i].projectname &&
          sitePageDefinitions[i].projectname !== ""
            ? sitePageDefinitions[i].projectname
            : "Wie zijn wij";
        const who = {
          menuname: menuname,
          href: sitePageDefinitions[i].name,
        };
        this.setState({ who });
      } else if (sitePageDefinitions[i].type === "project") {
        const menuname =
          sitePageDefinitions[i].projectname &&
          sitePageDefinitions[i].projectname !== ""
            ? sitePageDefinitions[i].projectname
            : "p";
        const project = {
          menuname: menuname,
          href: sitePageDefinitions[i].name,
          nr: parseInt(sitePageDefinitions[i].projectnr),
        };
        projects.push(project);
      }
    }
    projects.sort((firstItem, secondItem) => firstItem.nr - secondItem.nr);
    this.setState({ projects });
  }

  render() {
    const { user, who, projects } = this.state;

    return (
      <React.Fragment>
        {/* <h1>{document.referrer}</h1> * /}
        <NavBar2 user={user} projects={projects} who={who}></NavBar2>
        <main className="container">
          {/* <ScrollToTop /> * /}
          <Routes>
            <Route index element={<Home page="home" user={user} />} />
            <Route path="home" element={<Home page="home" user={user} />} />
            <Route path="anna" element={<Home page="anna" user={user} />} />
            <Route path="wie" element={<Home page="wie" user={user} />} />
            <Route path="contact" element={<Contact user={user} />} />
            <Route
              path="Ontmoetingen Online"
              element={<Home page="Ontmoetingen Online" user={user} />}
            />
            <Route
              path="internationaleontmoetingen"
              element={<Home page="internationaleontmoetingen" user={user} />}
            />
            <Route
              path="Muzikale Ontmoetingen"
              element={<Home page="Muzikale Ontmoetingen" user={user} />}
            />
            <Route
              path="huidhonger"
              element={<Home page="huidhonger" user={user} />}
            />
            <Route
              path="overigeprojecten"
              element={<Home page="overigeprojecten" user={user} />}
            />
            <Route path="nieuws" element={<Home page="nieuws" user={user} />} />
            <Route
              path="geschiedenis"
              element={<Home page="geschiedenis" user={user} />}
            />
            <Route path="sessie" element={<Home page="sessie" user={user} />} />
            <Route
              path="vrijwiliger"
              element={<Home page="vrijwiliger" user={user} />}
            />
            <Route
              path="ondersteuning"
              element={<Home page="ondersteuning" user={user} />}
            />
            <Route
              path="donaties"
              element={<Home page="donaties" user={user} />}
            />
            <Route path="overig" element={<Home page="overig" user={user} />} />
            <Route
              path="inschrijvenvoorsessie"
              element={<RegisterForSession user={user} />}
            />

            <Route path="overig" element={<Home page="overig" user={user} />} />
            <Route path="register" element={<RegisterForm />} />
            <Route path="login" element={<LoginForm />} />
            <Route path="logout" element={<Logout />} />
            <Route path="editinfosite" element={<EditInfoSite />} />
          </Routes>
        </main>
      </React.Fragment>
    );
  }
}

export default App;

*/

/*

react-router-dom v5

          <Routes>
            <Route
              path="/nl/:page"
              render={(props) => {
                if (props.match.url === "/nl/contact")
                  return <Contact {...props} user={this.state.user} />;
                if (
                  props.match.url.substring(0, 25) ===
                  "/nl/inschrijvenvoorsessie"
                )
                  return (
                    <RegisterForSession {...props} user={this.state.user} />
                  );
                return (
                  <Home
                    {...props}
                    user={this.state.user}
                    onPageEdit={this.state.onPageEdit}
                  />
                );
              }}
            />
            {/* <Route path="/" exact to="/signup" component={Home} /> 
              <ProtectedRoute path="/editinfosite" component={EditInfoSite} />
              <ProtectedRoute path="/editsessions" component={EditSessions} />* /}
            <Route path="/register" component={RegisterForm} />
            {/*<Route path="/registerws" component={RegisterWinterSpecialForm} /> * /}
            <Route path="/login" component={LoginForm} />
            <Route path="/logout" component={Logout} />
            {/* <Redirect path="/" exact to="/nl/home" />  * /}
            <Route path="*" element={<Navigate to="/nl/home" replace />} />
            {/* moet wel ( anders ) terug <Redirect to="/nl/home" /> * /}
            {/*<Redirect path="/" exact to="/registerws" /> * /}
          </Routes>


*/
