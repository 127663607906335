import React from "react";
/*
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
*/
const ProjectTable = ({ projects, setProjectId }) => {
  /*  const projectColumns = [
    {
      dataField: "name",
      text: "Naam",
      filter: textFilter(),
    },
  ];

  const projectRowEvents = {
    onClick: (e, row, rowIndex) => {
      setProjectId(row._id);
    },
  };

  const projectPagination = paginationFactory({
    sizePerPageList: [
      {
        text: "1",
        value: 1,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "Alle",
        value: projects.length,
      },
    ],
  });
*/
  return <div />;
  /*
  return (
    <BootstrapTable
      bootstrap4
      keyField="_id"
      data={projects}
      columns={projectColumns}
      striped
      hover
      condensed
      pagination={projectPagination}
      filter={filterFactory()}
      rowEvents={projectRowEvents}
    ></BootstrapTable>
  );
  */
};

export default ProjectTable;
