import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import FormTextInput from "../common/formTextInput";
import FormTextArea from "../common/formTextArea";
import FormSelect from "../common/formSelect";

class SitePageDefinitionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitePageDefinition: {},
      onSubmit: this.props.onSubmit,
      initialValues: {
        language: "",
        name: "",
        type: "",
        projectnr: 0,
        projectname: "",
        nrofcolumns: 0,
        title: "",
        remark: "",
        topSiteListObject: {},
      },
    };
  }

  populateSitePageDefinition() {
    //  try {
    //  console.log(
    //    "populate Form SitePageDefinition ",
    //    this.props.sitePageDefinition
    //  );
    this.setState({ sitePageDefinition: this.props.sitePageDefinition });
    const initialValues = this.mapToForm(this.props.sitePageDefinition);
    this.setState({ initialValues });
    //  } catch (ex) {
    //    console.log("Error getting ContactPerson on id ", ex);
    //    if (ex.response && ex.response.status === 404)
    //      this.props.history.replace("/not-found");
    //  }
  }

  mapToForm(sitePageDefinition) {
    let initialValues = sitePageDefinition;
    if (!initialValues.type || initialValues.type === "")
      initialValues.type = "std";
    // here new default values, not in the db, can be added
    return initialValues;
  }

  mapToDb(sitePageDefinition) {
    let sitePageDefinitiondb = sitePageDefinition;
    return sitePageDefinitiondb;
  }

  // populateSelectLists() {}

  async componentDidMount() {
    if (this.props.sitePageDefinition)
      // await this.populateContactPerson();
      this.populateSitePageDefinition();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.sitePageDefinition._id !== this.props.sitePageDefinition._id)
      this.populateSitePageDefinition();
    // await this.populateContactPerson();
  }

  doSubmit = async (values) => {
    //    const contactPersondb = this.mapToDb(values);
    //    console.log("ContactPerson to save ", contactPersondb);
    //    console.log(
    //     "ContactPerson to save json ",
    //      JSON.stringify(contactPersondb, null, 4)
    //    );
    this.state.onSubmit(values);
  };
  /*
  handleGiveOptionsFunction = (func) => {
    //    this.setState({ getOptions: func });
  };
*/
  render() {
    return (
      <>
        <Formik
          initialValues={this.state.initialValues}
          enableReinitialize
          validationSchema={Yup.object({
            //name: Yup.string().required("Vereist"),
            //value: Yup.string().required("Vereist"),
            //            typename: Yup.number().min(1).required("Kies"),
          })}
          onSubmit={async (values) => {
            this.doSubmit(values);
          }}
        >
          <div className="container">
            <Form>
              <div className="row">
                <div className="col-6">
                  {/* <!--left side --> */}
                  <div className="form-group row">
                    <FormSelect label="Taal" name="language">
                      <option value="">Selecteer een taal</option>
                      <option value="nl">Nederlands</option>
                    </FormSelect>
                  </div>
                  <br />
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Naam ( voor in de adresbalk van de browser en de doorverwijzing van een andere pagina )"
                      name="name"
                      type="text"
                    />
                  </div>
                  <div className="form-group row">
                    <FormSelect label="Type" name="type">
                      <option value="std">Standaard</option>
                      <option value="project">Project in menu</option>
                      <option value="news">Nieuws</option>
                      <option value="us">Wie zijn wij</option>
                      <option value="docs">Verdieping</option>
                    </FormSelect>
                  </div>

                  <div className="form-group row">
                    <FormTextInput
                      label="Project volgnr voor lijst in menu projecten"
                      name="projectnr"
                      type="number"
                    />
                  </div>
                  <div className="form-group row">
                    <FormTextInput
                      label="Project naam voor lijst in menu projecten"
                      name="projectname"
                      type="text"
                    />
                  </div>
                  <div className="form-group row">
                    <FormTextInput
                      label="Aantal kolommen op pagina"
                      name="nrofcolumns"
                      type="number"
                    />
                  </div>

                  <br />
                  <div className="form-group row">
                    <FormTextInput
                      label="Titel ( ook voor menu wanneer het niet de homepage betreft )"
                      name="title"
                      type="text"
                    />
                  </div>
                  <br />
                  <div className="form-group row">
                    <FormTextArea
                      label="Opmerkingen ( eigen gebruik: wat wil ik met deze pagina )"
                      name="remark"
                      type="text"
                    />
                  </div>
                  <br />
                  <button type="submit">Opslaan</button>
                </div>
              </div>
            </Form>
          </div>
        </Formik>
      </>
    );
  }
}

export default SitePageDefinitionForm;
