import http from "./httpService";

const apiEndpoint = "/sessionseries";

function sessionSerieUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getSessionSeries(fields, filter = null, type = null) {
  let url = apiEndpoint;
  let connect = "?";

  if (fields) {
    url = url + "?fields=" + fields;
    connect = "&";
  }
  if (filter) {
    url = url + connect + "filter=" + filter;
    connect = "&";
  }
  if (type) {
    url = url + connect + "type=" + type;
  }

  return http.get(url);
  /*
  if (fields) {
    return http.get(apiEndpoint + "?fields=" + fields);
  } else return http.get(apiEndpoint);
*/
}

export function getSessionSerie(sessionSerieId) {
  return http.get(sessionSerieUrl(sessionSerieId));
}

export function saveSessionSerie(sessionSerie) {
  if (sessionSerie._id && sessionSerie._id !== "new") {
    const body = { ...sessionSerie };
    delete body._id;
    return http.put(sessionSerieUrl(sessionSerie._id), body);
  }

  return http.post(apiEndpoint, sessionSerie);
}
/*
export function deleteSitePageObject(customerId) {
  return http.delete(sitePageObjectUrl(customerId));
}
*/
