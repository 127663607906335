import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

import JsonEditor2 from "./common/jsonEditor2";
// import "jsoneditor-react/es/editor.min.css";

import FormTextInput from "./common/formTextInput";
import FormTextArea from "./common/formTextArea";

import SurveyList from "./surveyList";
import { getSurveys, saveSurvey } from "../services/surveyService";

import { MdUpgrade } from "react-icons/md";

const SurveyInput = ({ user }) => {
  const [survey, setSurvey] = useState(null);
  const [surveys, setSurveys] = useState(null);
  const [loadingSurveys, setLoadingSurveys] = useState(true);
  const [json, setJson] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingSurveys(true);
      const { data: surveysDb } = await getSurveys();
      setSurveys(surveysDb);
      setLoadingSurveys(false);
    };
    fetchData();
  }, []);

  useFormikContext();

  if (loadingSurveys) {
    return <div />;
  } else if (!survey) {
    return (
      <SurveyList
        user={user}
        title={"Wijzigen/nieuwe survey"}
        surveys={surveys}
        setSurvey={setSurvey}
        setJson={setJson}
        other={true}
        otherText={"Nieuwe survey ( naam )"}
      />
    );
  }

  const handleJsonEditorChange = (value) => {
    setJson(value);
  };

  const doSubmit = async (values) => {
    const survey = values;
    //    if (json) survey.surveyJson = JSON.stringify(json, null, 2);
    const { data: surveyDb } = await saveSurvey(survey);
    setSurvey(surveyDb);
  };

  if (survey) {
    return (
      <>
        <h2>Enquete</h2>
        <Formik
          enableReinitialize={true}
          initialValues={survey}
          validationSchema={Yup.object({
            name: Yup.string().required("Vereist"),
            //value: Yup.string().required("Vereist"),
            //            typename: Yup.number().min(1).required("Kies"),
          })}
          onSubmit={async (values) => {
            doSubmit(values);
          }}
        >
          {({ handleChange, handleBlur, setFieldValue, values }) => (
            <div className="container">
              <Form>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Naam"
                      name="name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Versie"
                      name="version"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextArea
                      className="formLabel"
                      label="Omschrijving"
                      name="description"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <label>Json</label>
                    <JsonEditor2
                      name="survey"
                      value={json} // JSON.parse(values.surveyJson)}
                      onChange={(value) => {
                        //                        setJson(value);
                        setFieldValue(
                          "surveyJson",
                          JSON.stringify(value, null, 2)
                        );
                      }}
                    />
                    <br />
                    <MdUpgrade
                      fill="blue"
                      size="30"
                      onClick={(event) => {
                        try {
                          const str = values.surveyJson
                            .replaceAll("\n", "")
                            .replaceAll("\t", "");
                          const json = JSON.parse(str);
                          setJson(json);
                          setFieldValue("surveyJsonJE", json);
                        } catch (e) {
                          alert("vreselijke fout in json", e.message);
                        }
                      }}
                    />
                    <br />
                    <FormTextArea
                      className="formLabel"
                      label="Json"
                      name="surveyJson"
                      type="text"
                      rows="20"
                      style={{ "white-space": "pre-wrap" }}
                    />
                    <br />
                  </div>
                </div>
                <br />
                <br />
                <Button type="submit">Opslaan</Button>
              </Form>
            </div>
          )}
        </Formik>
      </>
    );
  }
  return <div />;
};

export default SurveyInput;

/*  const linkTo = "/customer?id=" + customerId; parameter optioneel

const CustomerForm = ({ customer, setCustomer }) => {
  const [localCustomer, setLocalCustomer] = useState({ description: "" });

  const doSubmit = async (values) => {
    const customer = values;
    const { data: customerDb } = await saveCustomer(customer);
    setCustomer(customerDb);
  };

  if (customer && customer !== localCustomer) {
    setLocalCustomer(customer);
  } else if (!customer && localCustomer._id) {
    const c = { ...localCustomer };
    for (let k in c) {
      c[k] = "";
    }
    setLocalCustomer(c);
  }

  useFormikContext();

  return (
    <>
      <h2>Klanten</h2>
      <Formik
        enableReinitialize={true}
        initialValues={localCustomer}
        validationSchema={Yup.object({
          name: Yup.string().required("Vereist"),
          //value: Yup.string().required("Vereist"),
          //            typename: Yup.number().min(1).required("Kies"),
        })}
        onSubmit={async (values) => {
          doSubmit(values);
        }}
      >
        {({ handleChange, handleBlur, setFieldValue, values }) => (
          <div className="container">
            <Form>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Nummer"
                    name="nr"
                    type="text"
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Naam"
                    name="name"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Contact persoon"
                    name="contact"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    className="formLabel"
                    label="Email"
                    name="email"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group row">
                  <FormTextInput
                    setFieldValue={(val) => setFieldValue("phone", val)}
                    className="formLabel"
                    label="Telefoonnummer"
                    name="phone"
                    type="text"
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: "15px" }}>
                <div className="form-group row">
                  <h6>Opmerkingen</h6>
                  <TextEditor
                    setFieldValue={(val) => setFieldValue("description", val)}
                    value={values.description}
                    name="text"
                  />
                </div>
              </div>

              <br />
              <br />
              <Button type="submit">Opslaan</Button>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default CustomerForm;
*/
