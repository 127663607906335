import http from "./httpService";

const apiEndpoint = "/sitemedias";

function siteMediaUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getSiteMedias(fields) {
  if (fields) {
    return http.get(apiEndpoint + "?fields=" + fields);
  } else return http.get(apiEndpoint);
}

export function getSiteMedia(siteMediaId) {
  return http.get(siteMediaUrl(siteMediaId));
}

export function saveSiteMedia(siteMedia) {
  if (siteMedia._id && siteMedia._id !== "new") {
    const body = { ...siteMedia };
    delete body._id;
    return http.put(siteMediaUrl(siteMedia._id), body);
  }

  return http.post(apiEndpoint, siteMedia);
}

export function deleteSiteMedia(siteMediaId) {
  return http.delete(siteMediaUrl(siteMediaId));
}
