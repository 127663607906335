import React, { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";
import "./textWithMore.css";

const ToggleButton = ({ isExpanded, onClick }) => {
  return (
    <a href="#" className="btn-toggle" onClick={onClick}>
      {isExpanded ? "minder..." : "meer..."}
    </a>
  );
};

export default function TextWithMore({ height, title, text, user }) {
  const [defaultHeight, setDefaultHeight] = useState(height);
  const [heightCurrent, setHeightCurrent] = useState(height);
  const [heightMax, setHeightMax] = useState(height);
  const [heightMin, setHeightMin] = useState(height);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(true);
  const [eventlistenerInstalled, setEventlistenerInstalled] = useState(false);

  const ref = useRef(null);

  let resizeWindow = () => {
    setDefaultHeight(height);
    const heightClient = height;
    //      ref && ref.current && ref.current.clientHeight
    //        ? ref.current.clientHeight
    //        : height;
    const scrollClient =
      ref && ref.current && ref.current.scrollHeight
        ? ref.current.scrollHeight
        : height;
    setIsOverflow(heightClient < scrollClient);
    setHeightMax(scrollClient);
    setHeightMin(heightClient < scrollClient ? height - 20 : height);
    setHeightCurrent(heightMin);
  };

  useEffect(() => {
    if (eventlistenerInstalled === false) {
      window.addEventListener("resize", resizeWindow);
      setEventlistenerInstalled(true);
    }

    //    return () => {
    //      console.log("dismount");
    //      window.removeEventListener("resize", resizeWindow);
    //    };
    setDefaultHeight(defaultHeight);
    setHeightCurrent(defaultHeight);
    setHeightMax(defaultHeight);
    setHeightMin(defaultHeight);
    resizeWindow();
  }, [height, ref, defaultHeight, eventlistenerInstalled]);

  const handleClickBtn = () => {
    setHeightCurrent(isExpanded ? heightMin : heightMax);
    setIsExpanded(!isExpanded);
  };

  if (height != defaultHeight) {
    resizeWindow();
  }

  return (
    <React.Fragment>
      <div>
        {height && height > 0 && (
          <div
            ref={ref}
            className={`${isExpanded ? "expanded" : "collapsed"} text-display`}
            style={{ height: `${heightCurrent}px` }}
          >
            <h2>
              {title}
              {/*user && user.userType && user.userType.isSiteAdmin && (
                <div
                  onContextMenu={(e) => {
                    e.preventDefault(); // prevent the default behaviour when right clicked
                    console.log("Right Click");
                  }}
                >
                  edit
                </div>
                )*/}
            </h2>
            {parse(text)}
          </div>
        )}

        {isOverflow && (
          <ToggleButton isExpanded={isExpanded} onClick={handleClickBtn} />
        )}
        {false && (
          <h6>
            height: {height}, defaultHeight: {defaultHeight}, heightCurrent:{" "}
            {heightCurrent}, heightMin: {heightMin}, heightMax: {heightMax}
          </h6>
        )}
      </div>
    </React.Fragment>
  );
}
