import http from "./httpService";

const apiEndpoint = "/surveys";

function surveyUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getSurveys(fields) {
  if (fields) {
    return http.get(apiEndpoint + "?fields=" + fields);
  } else return http.get(apiEndpoint);
}

export function getSurvey(surveyId) {
  return http.get(surveyUrl(surveyId));
}

export function saveSurvey(survey) {
  if (survey._id && survey._id !== "new") {
    const body = { ...survey };
    delete body._id;
    return http.put(surveyUrl(survey._id), body);
  }

  return http.post(apiEndpoint, survey);
}
/*
export function deleteSitePageObject(customerId) {
  return http.delete(sitePageObjectUrl(customerId));
}
*/
