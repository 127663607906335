import React from "react";
/*
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
*/

const CustomerTable = ({ customers, setCustomerId }) => {
  /*
  const customerColumns = [
    {
      dataField: "name",
      text: "Naam",
      filter: textFilter(),
    },
  ];

  const customerRowEvents = {
    onClick: (e, row, rowIndex) => {
      setCustomerId(row._id);
    },
  };

  const customerPagination = paginationFactory({
    sizePerPageList: [
      {
        text: "1",
        value: 1,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "Alle",
        value: customers.length,
      },
    ],
  });
*/
  return <div />;
  /*
  return (
    <BootstrapTable
      bootstrap4
      keyField="_id"
      data={customers}
      columns={customerColumns}
      striped
      hover
      condensed
      pagination={customerPagination}
      filter={filterFactory()}
      rowEvents={customerRowEvents}
    ></BootstrapTable>
  );
*/
};

export default CustomerTable;
