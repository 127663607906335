import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import "./textEditor.css";

class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //      parentUpdate: this.props.onChange,
      onBlur: this.props.onBlur,
      name: this.props.name,
      setFieldValue: this.props.setFieldValue,
    };
    this.updateEditor(this.props.value);
  }

  updateEditor(content) {
    const html = this.props.value
      ? this.props.value
      : "<p>Test <strong>editor</strong> with simple text 😀</p>";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState });
    }
  }

  componentDidMount() {
    this.updateEditor(this.props.value);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateEditor(this.props.value);
    }
  }

  /*
  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };
*/
  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  onBlurrer = () => {
    const res = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    this.state.setFieldValue(res);
  };

  render() {
    const { editorState } = this.state;
    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          onBlur={this.onBlurrer}
        />
      </div>
    );
  }
}

export default TextEditor;

/*

= ({ value, setFieldValue, name }) => {
  const [field, meta] = useField(name);

  const prepareDraft = (value) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  //  const [field, meta] = useField(name);

  const [editorState, setEditorState] = useState(
    value ? prepareDraft(value) : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue(forFormik);
    setEditorState(editorState);
    console.log("TextEditor forFormik ", forFormik);
    console.log(
      "TextEditor editorState ",
      convertToRaw(editorState.getCurrentContent()).blocks[0].text
    );
  };
  //  console.log(
  //    "TextEditor ",
  //    convertToRaw(editorState.getCurrentContent()).blocks[0].text
  //  );
  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="custom-wrapper"
        editorClassName="custom-editor"
        onEditorStateChange={onEditorStateChange}
        {...field}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
export default TextEditor;
*/
