import React, { useEffect, useCallback, useState } from "react";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";

import "survey-core/defaultV2.min.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";

import SurveyTable from "./surveyTable";
import SurveyForm from "./surveyForm";

import { getSurveys, getSurvey } from "../../../services/surveyService";

StylesManager.applyTheme("defaultV2");

const surveyJson = {
  elements: [
    {
      name: "FirstName",
      title: "Enter your first name:",
      type: "text",
    },
    {
      name: "LastName",
      title: "Enter your last name:",
      type: "text",
    },
  ],
};

const Surveys = () => {
  const [activeSurveyTab, setActiveSurveyTab] = useState();
  const [loadingSurveys, setLoadingSurveys] = useState(false);
  const [surveyId, setSurveyId] = useState(null);
  const [surveys, setSurveys] = useState([]);
  const [survey, setSurvey] = useState(null);
  const [surveyModel, setSurveyModel] = useState(null);

  const alertResults = useCallback((sender) => {
    const results = JSON.stringify(sender.data);
    alert(results);
  }, []);

  //

  useEffect(() => {
    const fetchData = async () => {
      setLoadingSurveys(true);
      const { data: surveysDb } = await getSurveys("nr name");
      setSurveys(surveysDb);
      setLoadingSurveys(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data: surveyDb } = await getSurvey(surveyId);
      setSurvey(surveyDb);
      try {
        const surveyModel = new Model(surveyDb.surveyJson);
        setSurveyModel(surveyModel);
        surveyModel.focusFirstQuestionAutomatic = false;
        surveyModel.onComplete.add(alertResults);
        setSurveyModel(surveyModel);
      } catch (err) {
        alert("fout in json: " + err.message);
      }
    };
    if (surveyId) fetchData();
  }, [surveyId]);

  return (
    <>
      <Tabs
        id="surveyTab"
        activeKey={activeSurveyTab}
        onSelect={(k) => setActiveSurveyTab(k)}
      >
        <Tab eventKey="edit" title="Enquete">
          <SurveyTable surveys={surveys} setSurveyId={setSurveyId} />
          <SurveyForm survey={survey} setSurvey={setSurvey} />
        </Tab>
        <Tab eventKey="test" title="Test">
          {surveyModel && <Survey model={surveyModel} />}
        </Tab>
        <Tab eventKey="results" title="Resultaten"></Tab>
      </Tabs>
    </>
  );
};

export default Surveys;
