import React from "react";
/*
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
*/
const SessionSerieTable = ({ sessionSeries, setSessionSerieId }) => {
  /*
  const sessionSerieColumns = [
    {
      dataField: "name",
      text: "Naam",
      filter: textFilter(),
    },
  ];

  const sessionSerieRowEvents = {
    onClick: (e, row, rowIndex) => {
      setSessionSerieId(row._id);
    },
  };

  const sessionSeriePagination = paginationFactory({
    sizePerPageList: [
      {
        text: "1",
        value: 1,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "Alle",
        value: sessionSeries.length,
      },
    ],
  });
  */
  return <div />;
  /*
  return (
    <BootstrapTable
      bootstrap4
      keyField="_id"
      data={sessionSeries}
      columns={sessionSerieColumns}
      striped
      hover
      condensed
      pagination={sessionSeriePagination}
      filter={filterFactory()}
      rowEvents={sessionSerieRowEvents}
    ></BootstrapTable>
  );
  */
};

export default SessionSerieTable;
