import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
//import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import TextWithMore from "../common/textWithMore";
import { getSitePageObject } from "../../services/sitePageObjectService";
import Media from "../common/media";
import parse from "html-react-parser";

class PageListItem extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      key: this.props.key,
      user: this.props.user,
      sitePageObjectId: null,
      sitePageObject: null,
      cellHeight: null,
    };
  }

  // navigate = useNavigate();
  // handleClickSession = () => alert('navigate("/goodbye")');

  async componentDidMount() {
    if (this.props.sitePageObjectId) {
      const { data: sitePageObject } = await getSitePageObject(
        this.props.sitePageObjectId
      );
      this.setState({ sitePageObjectId: this.props.sitePageObjectId });
      this.setState({ sitePageObject });
    }
  }

  async componentDidUpdate(prevProps) {
    //    const height = this.divElement ? this.divElement.clientHeight : 0;
    //    if (height > this.state.cellHeight) this.setState({ cellHeight: height });

    if (
      !prevProps.sitePageObjectId ||
      prevProps.sitePageObjectId !== this.props.sitePageObjectId
    ) {
      const { data: sitePageObject } = await getSitePageObject(
        this.props.sitePageObjectId
      );
      this.setState({ sitePageObject });
      this.setState({ sitePageObjectId: this.props.sitePageObjectId });
      // await this.populateContactPerson();
    }
  }

  setCellHeight = (cellHeight) => {
    this.setState({ cellHeight: cellHeight });
    // console.log(this.state.sitePageObject.title, cellHeight);
  };

  render() {
    const { sitePageObjectId, sitePageObject } = this.state;
    const linkTo = "/inschrijvenvoorsessie?id=" + sitePageObjectId;

    if (!sitePageObject || !sitePageObjectId) {
      return <div />;
    }
    const media = sitePageObject.siteMedias[0];

    return (
      <div
        ref={this.ref}
        style={{
          backgroundColor: this.props.backgroundColor,
          padding: "5px",
          bottommargin: "13px",
          marginTop: "10px",
        }}
      >
        {!this.props.fotoabove && (
          <Row className="pageList" id={sitePageObjectId} key={this.state.key}>
            {this.props.imagePos === "left" && (
              <Col
                sm={this.props.imageSize}
                className="bgColor"
                key={this.state.key}
              >
                {sitePageObject.elementType === "session" && (
                  <Link to={linkTo}>
                    <Media
                      media={media}
                      className="pageTopImage"
                      roundImage={sitePageObject.roundImage}
                      setCellHeight={this.setCellHeight}
                    />
                  </Link>
                )}
                {sitePageObject.elementType !== "session" && (
                  <Media
                    media={media}
                    className="pageTopImage"
                    roundImage={sitePageObject.roundImage}
                    setCellHeight={this.setCellHeight}
                  />
                )}
              </Col>
            )}
            <Col
              sm={12 - this.props.imageSize}
              className="bgColor"
              key={this.state.key}
            >
              <TextWithMore
                height={this.state.cellHeight}
                title={sitePageObject.title}
                text={sitePageObject.text}
                user={this.state.user}
              />
              {/*                <h2>{sitePageObject.title}</h2>
                {parse(sitePageObject.text)}
sitePageObject.linkUrl && (
                  <Link to={linkTo}> ... meer ...</Link>
                ) */}
            </Col>
            {this.props.imagePos === "right" && (
              <Col
                sm={this.props.imageSize}
                className="bgColor"
                key={this.state.key}
              >
                {sitePageObject.elementType === "session" && (
                  <Link to={linkTo}>
                    <Media
                      media={media}
                      className="pageTopImage"
                      roundImage={sitePageObject.roundImage}
                      setCellHeight={this.setCellHeight}
                    />
                  </Link>
                )}
                {sitePageObject.elementType !== "session" && (
                  <Media
                    media={media}
                    className="pageTopImage"
                    roundImage={sitePageObject.roundImage}
                    setCellHeight={this.setCellHeight}
                  />
                )}
              </Col>
            )}
          </Row>
        )}
        {this.props.fotoabove && (
          <>
            {sitePageObject.elementType === "session" && (
              <Link to={linkTo}>
                <Media
                  media={media}
                  className="pageTopImage"
                  roundImage={sitePageObject.roundImage}
                  setCellHeight={this.setCellHeight}
                />
              </Link>
            )}
            {sitePageObject.elementType !== "session" && (
              <Media
                media={media}
                className="pageTopImage"
                roundImage={sitePageObject.roundImage}
                setCellHeight={this.setCellHeight}
              />
            )}
            {parse(sitePageObject.text)}
          </>
        )}
      </div>
    );
  }
}
// was met withRouter
export default PageListItem;

/*

        <Col sm={12 - this.props.imageSize}>
          <h2>{sitePageObject.title}</h2>
          <p>
            {sitePageObject.text}
            {sitePageObject.linkUrl && (
              <Link to="/nl/${sitePageObject.linkUrl}"> ... meer ...</Link>
            {/* <Button
                onClick={() =>
                  this.props.history.push(`/nl/${sitePageObject.linkUrl}`)
                }
                color="primary"
              >
                Meer
              </Button> * /}
            )}
          </p>
        </Col>


*/
