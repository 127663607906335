import React, { useState, useEffect } from "react";
import { ButtonGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import SideBarContacts from "./sideBarContacts";

const SideBar = ({
  showSideBar,
  setShowSideBar,
  handleCloseSideBar,
  active,
  setValue,
  setValueId,
}) => {
  return (
    <Offcanvas
      className={"w-auto"} // width
      show={showSideBar}
      onHide={handleCloseSideBar}
      placement={"end"}
      scroll={true}
    >
      <Offcanvas.Header closeButton />
      <Offcanvas.Body>
        <SideBarContacts
          setValue={setValue}
          setValueId={setValueId}
          setShowSideBar={setShowSideBar}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SideBar;
