import http from "./httpService";

const apiEndpoint = "/sitepagedefinitions";

function sitePageDefinitionUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getSitePageDefinitions(fields) {
  if (fields) {
    return http.get(apiEndpoint + "?fields=" + fields);
  } else return http.get(apiEndpoint);
}

export function getSitePageDefinition(sitePageDefinitionId) {
  return http.get(sitePageDefinitionUrl(sitePageDefinitionId));
}

export function getSitePageDefinitionOnName(language, pagename) {
  return http.get(`${apiEndpoint}/${language}/${pagename}`);
}

export function getSitePageDefinitionSpecials(which) {
  return http.get(`${apiEndpoint}/${which}`);
}

export function saveSitePageDefinition(sitePageDefinition) {
  if (sitePageDefinition._id && sitePageDefinition._id !== "new") {
    const body = { ...sitePageDefinition };
    delete body._id;
    return http.put(sitePageDefinitionUrl(sitePageDefinition._id), body);
  }

  return http.post(apiEndpoint, sitePageDefinition);
}

export function deleteSitePageDefinition(sitePageDefinitionId) {
  return http.delete(sitePageDefinitionUrl(sitePageDefinitionId));
}
