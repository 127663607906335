import React, { useState, useCallback } from "react";

import "survey-core/modern.min.css";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";

const json = {
  elements: [
    {
      type: "radiogroup",
      name: "survey",
      title: "Kies een survey",
      choices: [
        /*        {
          value: "id_1",
          text: "survey 1",
        },
        {
          value: "id_2",
          text: "survey 2",
        },
      */
      ],
    },
  ],
  completeText: "Verder",
  showCompletedPage: false,
};

const SurveyList = ({
  user,
  title,
  surveys,
  setSurvey,
  setJson,
  other = false,
  otherText = "",
}) => {
  if (!json.elements[0].choices.length) {
    if (other) {
      json.elements[0].showOtherItem = true;
      json.elements[0].otherText = otherText;
    }
    for (var i = 0; i < surveys.length; i++) {
      json.elements[0].choices.push({
        value: surveys[i]._id,
        text: surveys[i].name,
      });
    }
  }
  const survey = new Model(json);

  survey.onComplete.add((sender, options) => {
    const res = sender.data;
    const id = res.survey;
    if (id === "other") {
      const survey = {
        name: res["survey-Comment"],
        version: "0.1",
        description: "",
        surveyJson: "{}",
      };
      setJson({});
      setSurvey(survey);
    } else {
      const survey = surveys.find((s) => s._id === id);
      setSurvey(survey);
      if (setJson) {
        try {
          const str = survey.surveyJson
            .replaceAll("\n", "")
            .replaceAll("\t", "");
          const json = JSON.parse(str);
          setJson(json);
        } catch (e) {
          setJson({});
          alert(
            "Invalid Json, wijzig onderin en sync ( pijl omhoog ) ",
            e.message
          );
        }
      }
    }
  });
  return (
    <>
      <h6>{title}</h6>
      <Survey model={survey} />
    </>
  );
};

export default SurveyList;
