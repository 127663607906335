import React, { Component } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import * as userService from "../services/userService";
//import auth from "../services/authService";

import FormTextInput from "./common/formTextInput";

class RegisterForm extends Component {
  state = {
    data: { email: "", password: "", name: "", phonenr: "" },
    errors: {},
  };

  /* PM: dit is joi format, moet yup worden 
  schema = {
    username: Joi.string().required().email().label("Username"),
    password: Joi.string().required().min(5).label("Password"),
    name: Joi.string().required().label("Name"),
  };
*/
  doSubmit = async (values) => {
    try {
      const response = await userService.register(values);
      // console.log("response ", response);
      // auth.loginWithJwt(response.headers["x-auth-token"]);
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        //errors.email = ex.response.data;
        alert("Fout: Email adres is al geregistreerd");
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div>
        <h1>Registreren</h1>
        <Formik
          initialValues={this.state.data}
          enableReinitialize
          validationSchema={Yup.object({
            //name: Yup.string().required("Vereist"),
            //value: Yup.string().required("Vereist"),
            //            typename: Yup.number().min(1).required("Kies"),
          })}
          onSubmit={async (values) => {
            this.doSubmit(values);
          }}
        >
          <div className="container">
            <Form>
              <div className="row">
                <div className="col-6">
                  {/* <!--left side --> */}
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Email"
                      name="email"
                      type="text"
                    />
                  </div>
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Wachtwoord"
                      name="password"
                      type="password"
                    />
                  </div>
                  <div className="form-group row">
                    <FormTextInput label="Naam" name="name" type="text" />
                  </div>
                  <div className="form-group row">
                    <FormTextInput
                      label="Telefoonnummer"
                      name="phonenr"
                      type="text"
                    />
                  </div>

                  <button type="submit">Registreer</button>
                </div>
              </div>
            </Form>
          </div>
        </Formik>
      </div>
    );
  }
}

export default RegisterForm;
