import React, { useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";

import SubmitButton from "../../common/submitButton";
import FormTextInput from "../../common/formTextInput";
import FormTextInputSearch from "../../common/formTextInputSearch";
import FormSelect from "../../common/formSelect";
import FormSelectList from "../../common/formSelectList";
import TextEditor from "../../common/textEditor2";

import { saveSessionSerie } from "../../../services/sessionSerieService";
import { getSurveys } from "../../../services/surveyService";
//  const linkTo = "/project?id=" + projectId; parameter optioneel

const SessionSerieForm = ({
  sessionSerie,
  setSessionSerie,
  projectId,
  projects,
  surveyList,
  setSurveyList,
}) => {
  const [localSessionSerie, setLocalSessionSerie] = useState({
    description: "",
  });
  //  const [surveyList, setSurveyList] = useState([]);

  const doSubmit = async (values) => {
    const sessionSerie = { ...values, projectName: "" };
    sessionSerie.projectId = projectId;
    const { data: sessionSerieDb } = await saveSessionSerie(sessionSerie);
    setSessionSerie({ sessionSerieDb });
  };

  const findProjectName = (pId) => {
    if (!pId) return "";
    if (!projects) return "";
    const p = projects.find((p) => p._id === pId);
    if (p && p.name) return p.name;
  };

  useEffect(() => {
    if (sessionSerie && sessionSerie !== localSessionSerie) {
      const projectName = findProjectName(sessionSerie.projectId);
      setLocalSessionSerie({ ...sessionSerie, projectName: projectName });
    } else if (!sessionSerie && localSessionSerie._id) {
      const ss = { ...localSessionSerie };
      for (let k in ss) {
        ss[k] = "";
      }
      setLocalSessionSerie(ss);
    } else if (!sessionSerie && projectId) {
      const ss = {
        projectId: projectId,
        projectName: findProjectName(projectId),
      };
      setLocalSessionSerie(ss);
    }
  }, [projectId, sessionSerie]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: surveysDb } = await getSurveys("name nr");
      const surveyList = { options: [] };
      surveyList.options.push({ value: null, name: "kies..." });
      surveysDb.map((s) => {
        surveyList.options.push({ value: s._id, name: s.name });
      });
      setSurveyList(surveyList);
    };
    fetchData();
  }, []);

  useFormikContext();

  if (!localSessionSerie) {
    return <div />;
  } else {
    return (
      <>
        <h2>Sessie serie</h2>
        <Formik
          enableReinitialize={true}
          initialValues={localSessionSerie}
          validationSchema={Yup.object({
            name: Yup.string().required("Vereist"),
            //value: Yup.string().required("Vereist"),
            //            typename: Yup.number().min(1).required("Kies"),
          })}
          onSubmit={async (values) => {
            doSubmit(values);
          }}
        >
          {({ handleChange, handleBlur, setFieldValue, values }) => (
            <div className="container">
              <Form>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Nummer"
                      name="nr"
                      type="text"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInputSearch
                      label="Project"
                      name="projectName"
                      disabled={true}
                      onClick={() => alert("nog niet geimplementeerd")}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Naam"
                      name="name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormSelect
                      label="Type"
                      name="sessionSerieType"
                      id="ssTyeId"
                      onChange={() => {
                        var x = document.getElementById("ssTyeId").value;
                        setFieldValue("sessionSerieType", x);
                        // checkElementTypeStates(x);
                        console.log(
                          "dinges changed ",
                          values.sessionSerieType,
                          x
                        );
                      }}
                    >
                      <option value="std">Standaard</option>
                      <option value="public">Publieke inschrijving</option>
                    </FormSelect>
                  </div>
                </div>
                {values.sessionSerieType === "public" && (
                  <>
                    <div className="row">
                      <div className="form-group row">
                        <FormTextInput
                          className="formLabel"
                          label="Start inschrijvingen"
                          name="enrollStart"
                          type="date"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group row">
                        <FormTextInput
                          className="formLabel"
                          label="Einde inschrijvingen"
                          name="enrollEnd"
                          type="date"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group row">
                        <FormTextInput
                          className="formLabel"
                          label="Max aantal inschrijvingen"
                          name="maxNrOf"
                          type="number"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      label="Site koppeling"
                      name="sitePageObjectName"
                      className="formLabel"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormSelectList
                      label="Enquete bij intake"
                      list={surveyList}
                      name="intakeSurveyId"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormSelectList
                      label="Enquete bij na einde serie"
                      list={surveyList}
                      name="exitSurveyId"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Coordinator naam"
                      name="contact"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Email"
                      name="email"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Telefoonnummer"
                      name="phone"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="form-group row">
                    <h6>Omschrijving</h6>
                    <TextEditor
                      setFieldValue={(val) => setFieldValue("description", val)}
                      value={values.description}
                      name="text"
                    />
                  </div>
                </div>
                <br />
                <br />
                <SubmitButton title="Opslaan" />
                <br />
              </Form>
            </div>
          )}
        </Formik>
      </>
    );
  }
};

export default SessionSerieForm;
