import React, { Component } from "react";
import SitePageObjectsTable from "../editInfoSite/sitePageObjectsTable";
import SessionForm from "./sessionForm";
import {
  getSitePageObjects,
  saveSitePageObject,
} from "../../services/sitePageObjectService";

class EditSessions extends Component {
  state = {
    sitePageObjects: [], // data
    sitePageObject: null,
    sitePageObjectId: null,
  };

  async componentDidMount() {
    const { data: sitePageObjects } = await getSitePageObjects();
    const result = sitePageObjects.filter((o) => o.elementType === "session"); // todo filter op session
    // const result = words.filter((word) => word.length > 6);
    this.setState({ sitePageObjects: result });
  }

  onSelectSession = async (sitePageObjectId, sitePageObject) => {
    this.setState({ sitePageObjectId });
    //   const { data: sessions } = await getSessions();

    if (!sitePageObject.sessionDates) sitePageObject.sessionDates = [];
    this.setState({ sitePageObject });
  };

  saveSessions = async () => {
    //    const sessionDates = values;
    const sitePageObject = this.state.sitePageObject;
    //    sitePageObject.sessionDates = sessionDates;
    this.setState({ sitePageObject });
    /*    // console.log("\nSave PageObject\n");
    // console.log(JSON.stringify(sitePageObject)); */
    const { data: sitePageObjectDb } = await saveSitePageObject(sitePageObject);
    // console.log("\nSave PageObject DB\n");
    // console.log(JSON.stringify(sitePageObjectDb));
    this.setState({ sitePageObject: sitePageObjectDb });

    const sitePageObjects = this.state.sitePageObjects;
    const index = sitePageObjects.findIndex(
      (s) => s._id === sitePageObjectDb._id
    );
    if (index !== -1) {
      sitePageObjects[index] = sitePageObjectDb;
    } else {
      sitePageObjects.push(sitePageObjectDb);
    }
    this.setState({ sitePageObjects });
    //    console.log("\nSave PageObject objects\n");
    //    console.log(JSON.stringify(sitePageObjects));
  };

  updateSitePageObject = (sessionDates) => {
    const spo = this.state.sitePageObject;
    spo.sessionDates = sessionDates;
    this.setState({ sitePageObject: spo });
  };

  render() {
    return (
      <React.Fragment>
        <SitePageObjectsTable
          sitePageObjects={this.state.sitePageObjects}
          handleSPOTableClick={this.onSelectSession}
          filterOnSitePageDefinition={false}
        />
        {this.state.sitePageObject && (
          <>
            <SessionForm
              sitePageObject={this.state.sitePageObject}
              doSubmit={this.saveSessions}
              doUpdateSitePageObject={this.updateSitePageObject}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

export default EditSessions;
