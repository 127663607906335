import React, { useState, useEffect, useMemo } from "react";

import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";

// https://codesandbox.io/p/devbox/tanstack-table-example-row-selection-2llvty?file=%2Fsrc%2Fmain.tsx%3A97%2C13

const SitePagesDefinitionTable = ({
  sitePageDefinitions,
  handleSPDTableClick,
}) => {
  const [data, setData] = useState([]);
  const [columnResizeMode, setColumnResizeMode] = useState("onChange"); // onEnd is alternatief

  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");

  useEffect(() => {
    setData(sitePageDefinitions);
  }, [sitePageDefinitions]);

  const onSelectRow = (_id) => {
    handleSPDTableClick(
      _id,
      sitePageDefinitions.filter((s) => s._id == _id)[0]
    );
  };

  const columns = useMemo(
    () => [
      {
        header: "Name",
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: "language",
            cell: (info) => info.getValue(),
            header: () => <span>Taal</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorFn: (row) => row.name,
            id: "name",
            cell: (info) => info.getValue(),
            header: () => <span>Naam</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
      {
        header: "Info",

        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: "title",
            id: "title",
            header: () => <span>Titel</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: "remark",
            id: "remark",
            header: () => <span>Opmerking</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: true, //enable row selection for all rows
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <div className="p-2">
      {/*
      <div>
        <input
          value={globalFilter ?? ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="p-2 font-lg shadow border border-block"
          placeholder="Search all columns..."
        />
      </div>
  */}
      <div className="h-2" />
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => {
                onSelectRow(row.original._id);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td className="sidebarTable" key={cell.id}>
                  {cell.getValue() && // RBo  wanneer value is null of key ontbreekt in object geeft crash
                    flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </button>
        <span className="flex items-center gap-1">
          Page
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded" // w-16"
            style={{ width: 60 }}
            min="1"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <br />
      <div>
        {Object.keys(rowSelection).length} of{" "}
        {table.getFilteredRowModel().rows.length} Total Rows Selected
      </div>
    </div>
  );
};

function Filter({ column, table }) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={column.getFilterValue()?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [e.target.value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={column.getFilterValue()?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [old?.[0], e.target.value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={column.getFilterValue() ?? ""}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Zoek...`}
      className="w-36 border shadow rounded"
    />
  );
}

//

export default SitePagesDefinitionTable;

/*

// @tanstac/react-table 1

import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table"; // https://tanstack.com/table/v8  https://tanstack.com/table/v8/docs/examples/react/row-selection

const SitePagesDefinitionTable = ({
  sitePageDefinitions,
  handleSPDTableClick,
}) => {
  const [data, setData] = useState([]);
  const [columnResizeMode, setColumnResizeMode] = useState("onChange"); // onEnd is alternatief

  useEffect(() => {
    setData(sitePageDefinitions);
  }, [sitePageDefinitions]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("language", {
      cell: (info) => info.getValue(),
      header: () => <span>Taal</span>,
      footer: (info) => info.column.id,
      minSize: 20,
      size: "5%",
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Naam</span>,
      footer: (info) => info.column.id,
      minSize: 100,
      size: "20%",
    }),
    columnHelper.accessor("title", {
      header: "Titel",
      footer: (info) => info.column.id,
      minSize: 120,
      size: "20%",
    }),
    columnHelper.accessor("remark", {
      header: "Opmerking",
      footer: (info) => info.column.id,
      minSize: 120,
      size: "55%",
    }),
    /*    columnHelper.accessor("_id", {
      header: "",
      cell: (info) => (
        <MdMode
          onClick={() => {
            alert(info.getValue());
          }}
        />
      ),
      minSize: 20,
      size: 20,
    }),* /
  ];

  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const onSelectRow = (_id) => {
    handleSPDTableClick(
      _id,
      sitePageDefinitions.filter((s) => s._id == _id)[0]
    );
  };

  return (
    <div className="p-2 sidebarTable">
      <table className="sidebarTable">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className="sidebarTable"
                  key={header.id}
                  style={{
                    width: header.column.columnDef.size,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => {
                onSelectRow(row.original._id);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td className="sidebarTable" key={cell.id}>
                  {cell.getValue() && // RBo  wanneer value is null of key ontbreekt in object geeft crash
                    flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </button>
        <span className="flex items-center gap-1">
          Page
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded" // w-16"
            style={{ width: 60 }}
            min="1"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SitePagesDefinitionTable;
*/
/*
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
*
// import { getSitePageDefinitions } from "../../services/sitePageDefinitionService";

function indication() {
  "Huh niets te doen????";
}

class SitePagesDefinitionTable extends React.Component {
  /*  state = {
    sitePageDefinitions: [],
    handleSPDTableClick: this.props.handleSPDTableClick,
    redirect: "",
  };

  componentDidMount() {
    //    const sitePageDefinitions = getSitePageDefinitions();
    const sitePageDefinitions = this.props.sitePageDefinitions;
    this.setState({ sitePageDefinitions });
    //  console.log(sitePageDefinitions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.sitePageDefinitions !== prevProps.sitePageDefinitions) {
      const sitePageDefinitions = this.props.sitePageDefinitions;
      this.setState({ sitePageDefinitions });
    }
  }

  render() {
    const columns = [
      {
        dataField: "language",
        text: "Taal",
        filter: textFilter(),
      },
      {
        dataField: "name",
        text: "Naam",
        filter: textFilter(),
      },
      {
        dataField: "title",
        text: "Title",
        sort: true,
      },
      {
        dataField: "remark",
        text: "Opmerking",
        sort: true,
      },
    ];
    const defaultSorted = [
      {
        dataField: "name",
        order: "desc",
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        //      onDoubleClick: (e, row, rowIndex) => {
        if (this.state.handleSPDTableClick !== undefined) {
          this.state.handleSPDTableClick(row._id, row);
          //        } else {
          //          this.setState({ redirect: `/organization/${row._id}` });
        }
      },
    };

    return (
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={this.state.sitePageDefinitions}
        columns={columns}
        striped
        hover
        condensed
        noDataIndication={indication}
        pagination={paginationFactory()}
        filter={filterFactory()}
        defaultSorted={defaultSorted}
        rowEvents={rowEvents}
      ></BootstrapTable>
    );
  } */

/*

import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";

// import { getOrganizations } from "./../../fakeServices/fakeOrganizationService";
import { getOrganizations } from "../../services/organizationService";
import { getSitePageDefinition } from './../../fakeServices/fakeSitePageDefinitionService';

function indication() {
  "Huh niets te doen????";
}

class OrganizationsTable extends Component {
  state = {
    organizationId: this.props.organizationId,
    organizations: [],
    handleOrgTableClick: this.props.handleOrgTableClick,
    redirect: "",
  };

  async componentDidMount() {
    const { data: organizations } = await getOrganizations();
    this.setState({ organizations });
    console.log(organizations);
  }

  render() {
    const columns = [
      {
        dataField: "name",
        text: "Naam",
        filter: textFilter(),
      },
      {
        dataField: "type.name",
        text: "Type",
        sort: true,
      },
      {
        dataField: "relation.name",
        text: "Relatie",
        sort: true,
      },
      {
        dataField: "phone",
        text: "Telefoonnr",
        sort: true,
      },
      {
        dataField: "city",
        text: "Plaats",
        sort: true,
      },
      {
        dataField: "partoff.name",
        text: "Onderdeel van",
        sort: true,
      },
      {
        dataField: "website",
        text: "Website",
        formatter: (cell) => (
          <a href={cell} target="_blank" rel="noreferrer">
            {cell}
          </a>
        ),
        sort: true,
      },
    ];
    const defaultSorted = [
      {
        dataField: "name",
        order: "desc",
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        //      onDoubleClick: (e, row, rowIndex) => {
        if (this.state.handleOrgTableClick !== undefined) {
          this.state.handleOrgTableClick(row._id);
        } else {
          this.setState({ redirect: `/organization/${row._id}` });
        }
      },
    };

    if (this.state.redirect !== "") {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={this.state.organizations}
        columns={columns}
        striped
        hover
        condensed
        noDataIndication={indication}
        pagination={paginationFactory()}
        filter={filterFactory()}
        defaultSorted={defaultSorted}
        rowEvents={rowEvents}
      ></BootstrapTable>
    );
  }
}


*/
