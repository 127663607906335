import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";

import { getContact } from "../services/contactService";

const MyPersonalPage = () => {
  const [contactId, setContactId] = useState(null);
  const [contact, setContact] = useState(null);
  const [rolesToConfirm, setRolesToConfirm] = useState([]);
  const [sessionSerieId, setSessionSerieId] = useState(null);
  const [sessionSerie, setSessionSerie] = useState(null);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  if (id !== contactId) {
    setContactId(id);
  }
  /*
  useEffect(() => {
    const fetchData = async (contactId) => {
      const { data: contactDb } = await getContact(contactId);
      setContact(contactDb);


      const roles2Confirm = contactDb.roles.filter((r) => r.type == "participant" && r.status != "confirmed");
      setRolesToConfirm(roles2Confirm);

      getSessionSeries // db.inventory.find( { quantity: { $in: [ 5, 15 ] } }, { _id: 0 } )


    const { data: sessionSeriesDb } = await getSessionSeries(
        "name enrollStart enrollEnd",
        "sitePageObjectId=" + sitePageObjectId,
        "sessions"
      );
      if (sessionSeriesDb.length) setSessionSeries(sessionSeriesDb);
    };


      // check status bevestiging inschrijvingen en haal agenda op
      /*      
      const { data: sessionSeriesDb } = await getSessionSeries(
        "name enrollStart enrollEnd",
        "sitePageObjectId=" + sitePageObjectId,
        "sessions"
      );
      if (sessionSeriesDb.length) setSessionSeries(sessionSeriesDb);
      * /
    };

    if (contactId) fetchData(contactId);
  }, [contactId]);
*/
  const confirmRegisterForSession = () => {
    console.log("confirm register for session");
  };

  if (!contact) return <div />;

  if (rolesToConfirm.length) {
    return (
      <>
        <h2>Hallo</h2>
        <p>Uw pagina {contact.name}</p>
        <p>Bevestiging van aanmelding voor:</p>
        {/* rolesToConfirm.map((r) => {<span><a href="#">r.name</a></span>}) */}

        <Button onClick={confirmRegisterForSession}>fiets</Button>
      </>
    );
  }
};

export default MyPersonalPage;
