import React from "react";
import { useState, useEffect } from "react";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";

import { MdDelete } from "react-icons/md";

import { getCustomer } from "../../services/customerService";
import { getProject } from "../../services/projectService";
import { getSessionSerie } from "../../services/sessionSerieService";
import { getSession } from "../../services/sessionService";

import Customer from "./customers/customer";
import Project from "./projects/project";
import SessionSerie from "./sessionSeries/sessionSerie";
import Session from "./sessions/session";

const CustomerProjectSession = () => {
  const [activeTab, setActiveTab] = useState("editCustomers");

  const [customerId, setCustomerId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);

  const [projectId, setProjectId] = useState(null);
  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState([]);

  const [sessionSerieId, setSessionSerieId] = useState(null);
  const [sessionSerie, setSessionSerie] = useState(null);
  const [sessionSeries, setSessionSeries] = useState([]);

  const [sessionId, setSessionId] = useState(null);
  const [session, setSession] = useState(null);
  const [sessions, setSessions] = useState([]);

  const [surveyList, setSurveyList] = useState([]);

  // load selected items customer / project / sessionSerie / session

  useEffect(() => {
    const fetchData = async () => {
      const { data: customerDb } = await getCustomer(customerId);
      setCustomer(customerDb);
    };
    if (customerId && (!customer || customer._id !== customerId)) fetchData();
  }, [customerId, customer]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: projectDb } = await getProject(projectId);
      setProject(projectDb);
    };
    if (projectId && (!project || project._id !== projectId)) fetchData();
  }, [projectId, project]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: sessionSerieDb } = await getSessionSerie(sessionSerieId);
      setSessionSerie(sessionSerieDb);
    };
    if (
      sessionSerieId &&
      (!sessionSerie || sessionSerie._id !== sessionSerieId)
    )
      fetchData();
  }, [sessionSerieId, sessionSerie]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: sessionDb } = await getSession(sessionId);
      setSession(sessionDb);
    };
    if (sessionId && (!session || session._id !== sessionId)) fetchData();
  }, [sessionId, session]);

  // delete Filters

  const resetCustomer = () => {
    setCustomerId(null);
    setCustomer(null);
  };

  const resetProject = () => {
    setProjectId(null);
    setProject(null);
  };

  const resetSessionSerie = () => {
    setSessionSerieId(null);
    setSessionSerie(null);
  };
  /*
  const resetSession = () => {
    setSessionId(null);
    setSession(null);
  };
*/
  return (
    <>
      <h4>
        Filter{" "}
        {customerId && customer && (
          <>
            klant: {customer.name}
            <MdDelete onClick={() => resetCustomer()} />
          </>
        )}
        {projectId && project && (
          <>
            project: {project.name}
            <MdDelete onClick={() => resetProject()} />
          </>
        )}
        {sessionSerieId && sessionSerie && (
          <>
            sessie serie: {sessionSerie.name}
            <MdDelete onClick={() => resetSessionSerie()} />
          </>
        )}
      </h4>
      {true && (
        <h6>
          c._id {customerId} p._id {projectId} ss._id {sessionSerieId}
        </h6>
      )}
      <Tabs
        id="controlled-tab"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
      >
        <Tab eventKey="editCustomers" title="Klanten">
          <Customer
            customer={customer}
            customers={customers}
            setCustomerId={setCustomerId}
            setCustomer={setCustomer}
            setCustomers={setCustomers}
          />
        </Tab>
        <Tab eventKey="editProject" title="Projecten">
          <Project
            customerId={customerId}
            customers={customers}
            project={project}
            projects={projects}
            setProjectId={setProjectId}
            setProject={setProject}
            setProjects={setProjects}
          />
        </Tab>
        <Tab eventKey="editSessionSerie" title="Sessie series">
          <SessionSerie
            projectId={projectId}
            projects={projects}
            sessionSerie={sessionSerie}
            sessionSeries={sessionSeries}
            setSessionSerieId={setSessionSerieId}
            setSessionSerie={setSessionSerie}
            setSessionSeries={setSessionSeries}
            surveyList={surveyList}
            setSurveyList={setSurveyList}
          />
        </Tab>
        <Tab eventKey="editSession" title="Sessies">
          <Session
            sessionSerieId={sessionSerieId}
            sessionSeries={sessionSeries}
            session={session}
            sessions={sessions}
            setSessionId={setSessionId}
            setSession={setSession}
            setSessions={setSessions}
            surveyList={surveyList}
            setSurveyList={setSurveyList}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default CustomerProjectSession;
