import React from "react";
import { useState, useEffect } from "react";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import { Row, Col } from "react-bootstrap";

import ProjectTable from "./projectTable";
import ProjectForm from "./projectForm";

import { getProjects } from "../../../services/projectService";

const Project = ({
  customerId,
  customers,
  project,
  projects,
  setProjectId,
  setProject,
  setProjects,
}) => {
  const [activeProjectTab, setActiveProjectTab] = useState("editNotes");
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingProjects(true);
      const { data: projectsDb } = await getProjects("name customerId");
      setProjects(projectsDb);
      filterProjects();
      setLoadingProjects(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterProjects();
  }, [customerId]);

  const filterProjects = () => {
    if (!customerId) {
      setFilteredProjects({ ...projects });
    } else {
      setFilteredProjects(projects.filter((p) => p.customerId === customerId));
    }
  };

  if (loadingProjects) {
    return <div />;
  }

  return (
    <>
      <Row>
        <ProjectTable projects={filteredProjects} setProjectId={setProjectId} />
        <Col className="sm-7">
          <ProjectForm
            project={project}
            setProject={setProject}
            customerId={customerId}
            customers={customers}
          />
        </Col>
        <Col className="sm-5">
          <Tabs
            id="projectTab"
            activeKey={activeProjectTab}
            onSelect={(k) => setActiveProjectTab(k)}
          >
            <Tab eventKey="editNotes" title="Notities"></Tab>
            <Tab eventKey="editContacts" title="Contacten"></Tab>
            <Tab eventKey="editAdresses" title="Locaties"></Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default Project;
